import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Wallet = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 12C11 9.79086 12.7909 8 15 8H20C21.1046 8 22 8.89543 22 10V14C22 15.1046 21.1046 16 20 16H15C12.7909 16 11 14.2091 11 12ZM15 10C13.8954 10 13 10.8954 13 12C13 13.1046 13.8954 14 15 14H20V10H15Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 12C14 11.4477 14.4477 11 15 11L15.1 11C15.6523 11 16.1 11.4477 16.1 12C16.1 12.5523 15.6523 13 15.1 13L15 13C14.4477 13 14 12.5523 14 12Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);
