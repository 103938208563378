import React from 'react';
import { Button, Box, useTheme } from '@mui/material';
import { BaseElementProps } from '../types';

export const AdBoardButton = ({ children, ...props }: BaseElementProps) => {
  const { palette } = useTheme();
  return (
    <Button
      sx={{
        width: '100%',
        backgroundColor: palette.keyops.blue.midnightBlue,
        '&:hover': {
          backgroundColor: palette.keyops.blue.midnightBlue50,
        },
      }}
      variant='contained'
      disableElevation
      {...props}
    >
      <Box
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {children}
      </Box>
    </Button>
  );
};
