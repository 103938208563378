import React, { useMemo } from 'react';
import { Box, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';

import { Loader } from '../Loader';
import { KeyopsHeader2, KeyopsSubTitle1, KeyopsSubTitle2 } from '../Headers';
import { NotificationsList } from '../NotificationsList';
import { NotificationsEmptyState } from '../NotificationsEmptyState';
import { DisplayNotification } from '../../utils/notifications-utils';
import i18next from '../../languages/i18n.config';

const notificationsCutOff = 5;
interface NotificationsRendererProps {
  handleClose: () => void;
  loadingNotifications: boolean;
  notificationsError: string | null;
  notificationsList: DisplayNotification[];
  showAllNotifications?: boolean;
  toggleShowAllNotifications?: (open: boolean) => void;
}

const CenterContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    flexGrow={1}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    gap={2}
    mt={-4}
  >
    {children}
  </Box>
);

export const NotificationsRenderer = ({
  handleClose,
  loadingNotifications,
  notificationsError,
  notificationsList,
  showAllNotifications,
  toggleShowAllNotifications,
}: NotificationsRendererProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const notificationsToShow = useMemo(() => {
    if (isMobile) return notificationsList;
    return showAllNotifications
      ? notificationsList
      : notificationsList.slice(0, notificationsCutOff);
  }, [showAllNotifications, notificationsList]);

  const unreadNotificationsCount = notificationsList.filter(
    (notification) => !notification.read
  ).length;

  const buildContent = () => {
    if (loadingNotifications)
      return (
        <CenterContent>
          <Loader size='5rem' pt={0} />
        </CenterContent>
      );
    if (notificationsError)
      return (
        <CenterContent>
          <KeyopsSubTitle2>{notificationsError}</KeyopsSubTitle2>
        </CenterContent>
      );
    if (notificationsList.length > 0) {
      return (
        <Box flexGrow={1} overflow={'auto'}>
          <NotificationsList
            list={notificationsToShow}
            handleClose={handleClose}
          />
        </Box>
      );
    }
    return (
      <CenterContent>
        <NotificationsEmptyState />
      </CenterContent>
    );
  };

  const buildFooter = () => {
    const allNotificationsText = i18next.t(
      'notifications.notificationsList.showingAllNotifications',
      {
        count: notificationsList.length,
      }
    );

    if (
      isMobile ||
      notificationsList.length <= notificationsCutOff ||
      showAllNotifications
    ) {
      return allNotificationsText;
    }

    return (
      <Trans
        defaults={i18next.t(
          'notifications.notificationsList.showingNotifications',
          {
            count: notificationsCutOff,
          }
        )}
        components={{
          buttonTag: (
            <Link
              component='button'
              underline='none'
              onClick={() => toggleShowAllNotifications(true)}
            />
          ),
        }}
      />
    );
  };

  return (
    <Box data-testid='notifications-renderer'>
      <Box px={2} py={2} position='sticky' top={0} zIndex={1} bgcolor={'#fff'}>
        <KeyopsHeader2 display={'inline-block'}>
          {i18next.t('notifications.title')}
        </KeyopsHeader2>{' '}
        <KeyopsSubTitle1 display={'inline-block'}>
          {unreadNotificationsCount > 0 && `(${unreadNotificationsCount})`}
        </KeyopsSubTitle1>
      </Box>
      {/* content here */}
      {isMobile ? (
        buildContent()
      ) : (
        <Stack
          minHeight={notificationsList.length === 0 ? 280 : 240}
          maxHeight={480}
          width={450}
          direction='column'
          overflow='hidden'
        >
          {buildContent()}
        </Stack>
      )}
      {/* footer here */}
      <Box
        px={2.5}
        py={2}
        position='sticky'
        bottom={0}
        zIndex={1}
        bgcolor={'#fff'}
        textAlign={'center'}
      >
        {notificationsList.length > 0 && (
          <KeyopsSubTitle2 fontSize={'0.75rem'}>
            {buildFooter()}
          </KeyopsSubTitle2>
        )}
      </Box>
    </Box>
  );
};
