import { Typography } from '@mui/material';
import React from 'react';
import { BaseElementProps } from '../types';

export const KeyopsHeader1 = ({ children, ...props }: BaseElementProps) => {
  return (
    <Typography variant={'h1'} {...props}>
      {children}
    </Typography>
  );
};

export const KeyopsHeader2 = ({ children, ...props }: BaseElementProps) => {
  return (
    <Typography variant={'h2'} {...props}>
      {children}
    </Typography>
  );
};

export const KeyopsHeader3 = ({ children, ...props }: BaseElementProps) => {
  return (
    <Typography variant={'h3'} {...props}>
      {children}
    </Typography>
  );
};

export const KeyopsSubTitle1 = ({ children, ...props }: BaseElementProps) => {
  return (
    <Typography variant={'subtitle1'} {...props}>
      {children}
    </Typography>
  );
};

//not sure if this will needed, for now, change it to use subtitle1
export const KeyopsSubTitle2 = ({ children, ...props }: BaseElementProps) => {
  return (
    <Typography variant={'subtitle1'} {...props}>
      {children}
    </Typography>
  );
};
