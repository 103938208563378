import React from 'react';
import { Box, useTheme } from '@mui/material';
import { FiBell } from 'react-icons/fi';

import { KeyopsHeader2, KeyopsSubTitle2 } from '../Headers';
import i18next from '../../languages/i18n.config';

export const NotificationsEmptyState = () => {
  const { palette } = useTheme();

  return (
    <>
      <FiBell
        data-testid='notification-icon'
        fontSize={62}
        color={palette.keyops.blue.midnightBlue}
      />
      <Box textAlign={'center'}>
        <KeyopsHeader2>
          {i18next.t('notifications.emptyState.title')}
        </KeyopsHeader2>
        <KeyopsSubTitle2 color={palette.keyops.black.main}>
          {i18next.t('notifications.emptyState.subTitle')}
        </KeyopsSubTitle2>
      </Box>
    </>
  );
};
