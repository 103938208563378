// External Dependencies
import React from 'react';
import {
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  FunctionField,
  TopToolbar,
  TabbedShowLayout,
  Show,
  ReferenceField,
  ReferenceManyField,
} from 'react-admin';
import { ShowHcpProfile } from './ShowHcpProfile';
import { MixPanelLink } from './utils';

const UserShowActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const UserShow = (props) => {
  return (
    <Show actions={<UserShowActions />} {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label='User'>
          <MixPanelLink />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Main details
          </h4>
          <TextField source='id' />
          <TextField source='hcpType' />
          <FunctionField
            label='Name'
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
          <TextField source='email' />
          <TextField source='state' />
          <BooleanField source='involvementCommittee' />
          <BooleanField source='termsOfService' />
          <TextField source='lastSignInAt' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Location
          </h4>
          <TextField source='country' />
          <TextField source='provinceOfPractice' />
          <TextField source='cityOfPractice' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Mobile
          </h4>
          <TextField source='mobileNumber' />
          <BooleanField source='smsNotifications' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Demographics
          </h4>
          <TextField source='yearOfBirth' />
          <TextField source='gender' />
          <TextField source='ethnicity' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Admin only
          </h4>
          <TextField source='adminNotes' />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label='HCP Profile'>
          <ShowHcpProfile />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label='Invitations'>
          <ReferenceManyField
            reference='Invitation'
            target='userId'
            addLabel={false}
            filter={{ type: 'invitation' }}
          >
            <Datagrid>
              <ReferenceField
                label='Engagement'
                source='engagementId'
                reference='Engagement'
              >
                <TextField source='title' />
              </ReferenceField>
              <TextField source='state' />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
