export const AdBoardInviteStates = [
  'draft',
  'sent',
  'pending-contract',
  'declined',
  'contract-sent-error',
  'confirmed',
] as const;

export type AdBoardInviteState = (typeof AdBoardInviteStates)[number];
