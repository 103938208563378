import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilledMessage2 = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='m12 2c-5.52285 0-10 4.47715-10 10 0 1.8153.48451 3.5196 1.33127 4.9883.17245.2991.20203.6633.0565.9764l-.85371 1.8369c-.5242.9917.1933 2.1984 1.32753 2.1984h8.13841c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10zm-3 7c-.55228 0-1 .44772-1 1 0 .5523.44772 1 1 1h2c.5523 0 1-.4477 1-1 0-.55228-.4477-1-1-1zm0 4c-.55228 0-1 .4477-1 1s.44772 1 1 1h6c.5523 0 1-.4477 1-1s-.4477-1-1-1z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  </SvgIcon>
);
