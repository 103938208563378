import React, { forwardRef, useRef, useEffect } from 'react';
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  useTheme,
} from '@mui/material';

import i18next from '../../languages/i18n.config';
import { COMPONENT_FEATURE_FLAGS } from '../../utils/component-feature-flags';

interface MentionsTextAreaProps {
  inputValue: string;
  handleInputValueChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  showSuggestions: boolean;
  suggestions: User[];
  handleSuggestionClick: (suggestion: User) => void;
  onSave: () => void;
}

interface User {
  id: string;
  name: string;
}

const MentionsTextArea = forwardRef<HTMLTextAreaElement, MentionsTextAreaProps>(
  (
    {
      inputValue,
      handleInputValueChange,
      showSuggestions,
      suggestions,
      handleSuggestionClick,
      onSave,
    },
    ref
  ) => {
    const { palette } = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (containerRef.current && showSuggestions) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, [showSuggestions]);

    return (
      <div ref={containerRef} style={{ position: 'relative' }}>
        <TextField
          label={i18next.t('video.comments.fieldLabel')}
          id='video-comments-input'
          aria-describedby='video-comments-input'
          inputRef={ref}
          variant='outlined'
          multiline
          fullWidth
          maxRows={4}
          value={inputValue}
          onChange={handleInputValueChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Button
                  data-testid='save-comment'
                  variant='contained'
                  onClick={onSave}
                  sx={{ bgcolor: palette.keyops.blue.midnightBlue }}
                >
                  {i18next.t('comments.comment')}
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {COMPONENT_FEATURE_FLAGS.MENTIONS && showSuggestions && (
          <List
            sx={{
              position: 'absolute',
              top: '100%', // Directly below the textarea
              left: 0,
              width: '100%',
              background: '#fff',
              padding: 0,
              margin: 0,
              zIndex: 2,
            }}
          >
            {suggestions.map((suggestion) => (
              <ListItem key={suggestion.id} disablePadding>
                <ListItemButton
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <ListItemText primary={suggestion.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    );
  }
);

MentionsTextArea.displayName = 'MentionsTextArea';
export default MentionsTextArea;
