import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { EventTypes } from '@keyops-hcp/constants';

import {
  DisplayNotification,
  generateDisplayNotifications,
} from '../utils/notifications-utils';
import {
  getAllNotifications,
  updateReadStatusCall,
} from '../api/notifications';

interface UseNotificationsResult {
  list: DisplayNotification[];
  setList: Dispatch<SetStateAction<DisplayNotification[]>>;
  unread: boolean;
  setUnread: Dispatch<SetStateAction<boolean>>;
  updateReadStatus: () => void;
  loading: boolean;
  error: string | null;
}

export const useNotifications = (): UseNotificationsResult => {
  const [list, setList] = useState<DisplayNotification[]>([]);
  const [unread, setUnread] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [notificationError, setNotificationError] = useState<string | null>(
    null
  );

  const fetchNotifications = async () => {
    try {
      const results = await getAllNotifications();
      // Filter notifications
      const filteredResults = results.filter((notification) => {
        if (EventTypes.includes(notification.event.eventType)) return true;
        console.log(
          `event type ${notification.event.eventType} unrecognized for - ${notification.id}`
        );
      });
      const generatedNotifications =
        generateDisplayNotifications(filteredResults);
      setList(generatedNotifications);
      setUnread(
        generatedNotifications.some((notification) => !notification.read)
      );
      setNotificationError(null);
    } catch (error) {
      console.error('error fetching notifications', error);
      setNotificationError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 30000); // Poll every 30 seconds

    return () => clearInterval(intervalId);
  }, []);

  const updateReadStatus = useCallback(async () => {
    try {
      const unreadNotifications = list
        .filter((notification) => !notification.read)
        .map((notification) => notification.id);

      if (!unreadNotifications.length) return;

      await updateReadStatusCall(unreadNotifications);
      setUnread(false);
      setList(list.map((item) => (item.read ? item : { ...item, read: true })));
    } catch (e) {
      console.error('error updateReadStatus - ', e);
    }
  }, [list]);

  return {
    list,
    setList,
    unread,
    setUnread,
    updateReadStatus,
    loading,
    error: notificationError,
  };
};
