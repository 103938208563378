import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Stack } from '@mui/material';
import { useStyles } from './styles';
import { BaseElementProps } from '../../../../components/types';

export const CallToActionBox = ({
  children,
  title,
  icon,
  ...props
}: BaseElementProps & { title: string; icon?: React.ReactNode }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{ ...styles.box, flexWrap: isSmall ? 'wrap' : 'nowrap' }}
      {...props}
    >
      {icon}
      <Stack spacing={2}>
        <Typography sx={styles.title}>{title}</Typography>
        {children}
      </Stack>
    </Box>
  );
};
