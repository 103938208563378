import React from 'react';
import { FiCheckCircle, FiCircle } from 'react-icons/fi';
import { useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { KeyopsSubTitle2 } from '../Headers';

interface ActivityListItemProps {
  text: string;
  sectionId: string;
  finished: boolean;
  accessible: boolean;
  setCurrentSectionId: (id: string, contentId?: string) => void;
}

export const ActivityListItem = ({
  text,
  sectionId,
  finished,
  setCurrentSectionId,
  accessible,
}: ActivityListItemProps): JSX.Element => {
  const { palette } = useTheme();
  const { sectionId: urlSectionId } = useParams();

  let selected = false;
  if (urlSectionId && urlSectionId === sectionId) selected = true;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexGrow={1}
      gap={1}
      pl={5}
      pr={2}
      py={1}
      sx={{
        cursor: accessible ? 'pointer' : 'default',
        backgroundColor: selected ? palette.keyops.gray[50] : 'transparent',
        '&:hover': {
          backgroundColor: accessible
            ? palette.keyops.gray[100]
            : 'transparent',
        },
        opacity: accessible ? 1 : 0.5, // Grays out the entire Box
      }}
      onClick={() => {
        if (accessible) {
          setCurrentSectionId(sectionId);
        }
      }}
    >
      {finished ? (
        <FiCheckCircle
          data-testid='check-circle-icon'
          fontSize={20}
          color={palette.keyops.teal}
          style={{ minWidth: 20 }}
        />
      ) : (
        <FiCircle
          data-testid='circle-icon'
          fontSize={18}
          style={{ minWidth: 20 }}
        />
      )}
      <KeyopsSubTitle2
        className='suspendAfterTwoLines'
        sx={{
          color: accessible ? 'inherit' : palette.keyops.gray[500], // Grays out the text
        }}
      >
        {text}
      </KeyopsSubTitle2>
    </Box>
  );
};
