import React from 'react';
import { KeyOpsThemeProvider } from '@keyops-hcp/ui-components';

import EngagementTabContent from './engagement/EngagementTabContent';

const EngagementTab = () => {
  return (
    <KeyOpsThemeProvider>
      <EngagementTabContent />
    </KeyOpsThemeProvider>
  );
};

export default EngagementTab;
