import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAdBoardContext } from '../../context';
import { SectionActivitiesView } from '../SectionActivitiesView';
import { Loader } from '../Loader';
import i18next from '../../languages/i18n.config';

interface SectionNavigationContentProps {
  handleSectionDrawerToggle: () => void;
  setCurrentSectionId: (id: string) => void;
}

export const SectionNavigationContent = ({
  handleSectionDrawerToggle,
  setCurrentSectionId,
}: SectionNavigationContentProps): JSX.Element => {
  const { isLoading, isError, sectionGroupsData } = useAdBoardContext();

  if (isError) return <>Something went wrong</>;

  return (
    <>
      <Box
        p={2}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant='body1' fontWeight={500}>
          {i18next.t('ad_board.sectionsDrawer.adBoardContent')}
        </Typography>
        <IconButton size='small' onClick={handleSectionDrawerToggle}>
          <CloseIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>
      <Divider />
      <Box flexGrow={1}>
        {(isLoading || !sectionGroupsData || sectionGroupsData.length <= 0) && (
          <Loader size='3rem' pt={2} />
        )}
        {sectionGroupsData &&
          sectionGroupsData.length > 0 &&
          sectionGroupsData.map((sectionGroup) => (
            <SectionActivitiesView
              key={sectionGroup.id}
              sectionGroup={sectionGroup}
              setCurrentSectionId={setCurrentSectionId}
            />
          ))}
        <Divider sx={{ mt: 2 }} />
      </Box>
    </>
  );
};
