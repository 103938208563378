import React from 'react';
import { Stack } from '@mui/material';
import {
  DiscussionAdBoardSectionContentDto,
  AdBoardDiscussionSectionProgressCombined,
} from '@keyops-hcp/dtos';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { KeyopsSubTitle1 } from '../Headers';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context/AdBoardContext';
import { DiscussionToggle } from './DiscussionToggle';
import { TopicDisplay } from './TopicDisplay';

export const DiscussionList = ({
  content,
  setIsFullScreenData,
  sectionNavigate,
  topicId,
}: {
  content: DiscussionAdBoardSectionContentDto[]; // For now it's being pulled from the adBoard section content
  setIsFullScreenData: (isFullScreen: boolean) => void;
  sectionNavigate: (contentId?: string) => void;
  topicId?: string;
}) => {
  const { currentSectionProgress } = useAdBoardContext();
  const sectionProgress =
    currentSectionProgress as AdBoardDiscussionSectionProgressCombined;

  if (topicId) {
    setIsFullScreenData(true);
    return (
      <TopicDisplay
        topicId={topicId}
        setIsFullScreenData={setIsFullScreenData}
        sectionNavigate={sectionNavigate}
      />
    );
  }

  return (
    <Stack spacing={2}>
      <AdBoardSectionTaskInfo>
        {i18next.t('ad_board.discussion.infoText')}
      </AdBoardSectionTaskInfo>
      <KeyopsSubTitle1>
        {i18next.t('ad_board.discussion.totalTopics', {
          count: content.length,
        })}
      </KeyopsSubTitle1>
      {content?.map((discussions) => {
        return (
          <DiscussionToggle
            isFinished={
              !!sectionProgress?.finishedTopics?.includes(
                discussions?.discussion?.id
              )
            }
            topicTitle={discussions?.discussion?.topic}
            topicId={discussions.discussion.id}
            messageCount={discussions.discussion.messages.length}
            sectionNavigate={sectionNavigate}
          />
        );
      })}
    </Stack>
  );
};
