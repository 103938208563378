export const Ethnicities = [
  "no-response",
  "first-nation",
  "alaskan-native",
  "inuit",
  "metis",
  "other-aboriginal",
  "white-Caucasian",
  "other-european",
  "black",
  "caribbean",
  "latin-american",
  "african",
  "west-asia-middle-east",
  "South Asian",
  "east-asia",
  "oceania",
] as const;

export type Ethnicity = (typeof Ethnicities)[number];
