import React from 'react';

import { ShowPharmacistProfile } from './ShowPharmacistProfile';
import { ShowPhysicianProfile } from './ShowPhysicianProfile';
import { ShowNurseProfile } from './ShowNurseProfile';
import {
  ConditionalModeratorFields,
  ConditionalNurseFields,
  ConditionalPharmacistFields,
  ConditionalPhysicianFields,
} from './utils';
import { ShowModeratorProfile } from './ShowModeratorProfile';

export const ShowHcpProfile = () => {
  return (
    <>
      <ConditionalPhysicianFields>
        <ShowPhysicianProfile />
      </ConditionalPhysicianFields>
      <ConditionalPharmacistFields>
        <ShowPharmacistProfile />
      </ConditionalPharmacistFields>
      <ConditionalNurseFields>
        <ShowNurseProfile />
      </ConditionalNurseFields>
      <ConditionalModeratorFields>
        <ShowModeratorProfile />
      </ConditionalModeratorFields>
    </>
  );
};
