import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Link = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='28'
        height='22'
        viewBox='0 0 28 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M26.0367 11.5473C28.6544 8.90436 28.6544 4.62421 26.0367 1.98127C23.7202 -0.357607 20.0693 -0.661661 17.4053 1.2609L17.3312 1.31235C16.664 1.79416 16.5111 2.72972 16.9883 3.39864C17.4655 4.06756 18.3922 4.2266 19.0547 3.74479L19.1288 3.69334C20.616 2.62213 22.65 2.79053 23.938 4.09562C25.3974 5.56912 25.3974 7.95478 23.938 9.42827L18.7396 14.6861C17.2802 16.1596 14.9173 16.1596 13.4579 14.6861C12.1653 13.381 11.9985 11.3274 13.0595 9.83056L13.1104 9.75572C13.5877 9.08212 13.4301 8.14657 12.7676 7.66943C12.1051 7.1923 11.1738 7.34667 10.7013 8.01559L10.6503 8.09043C8.74146 10.7755 9.04261 14.4615 11.3591 16.8004C13.9768 19.4434 18.2161 19.4434 20.8338 16.8004L26.0367 11.5473ZM1.96327 10.4527C-0.654422 13.0956 -0.654422 17.3758 1.96327 20.0187C4.27981 22.3576 7.93067 22.6617 10.5947 20.7391L10.6688 20.6876C11.336 20.2058 11.4889 19.2703 11.0117 18.6014C10.5345 17.9324 9.60784 17.7734 8.94531 18.2552L8.87119 18.3067C7.38397 19.3779 5.35005 19.2095 4.06205 17.9044C2.60263 16.4262 2.60263 14.0405 4.06205 12.567L9.26036 7.31392C10.7198 5.84043 13.0827 5.84043 14.5421 7.31392C15.8347 8.61902 16.0015 10.6726 14.9405 12.1741L14.8896 12.249C14.4123 12.9226 14.5699 13.8581 15.2324 14.3352C15.8949 14.8124 16.8262 14.658 17.2988 13.9891L17.3497 13.9142C19.2585 11.2245 18.9574 7.53846 16.6409 5.19957C14.0232 2.55664 9.7839 2.55664 7.16621 5.19957L1.96327 10.4527Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
