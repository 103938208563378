import React, { SyntheticEvent } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { BottomNavBarDrawer } from '../BottomNavBarDrawer';
import { TopNavBarPopover } from '../TopNavBarPopover';

export interface ResponsiveNavBarContentProps {
  contentType: string; // type of the content to display (notifications, profile, ...)
  isOpen: boolean;
  onClose: () => void;
  onOpen?: (event: SyntheticEvent<object, Event>) => void;
  contentAnchor?: HTMLButtonElement | SVGSVGElement | null; // The html element that is the popover anchor
  topOffset?: string; // Optional top offset for the popover
  minHeight?: number | string;
  maxHeight?: number | string;
  children: React.ReactNode;
}

/**
 * Used for nav sections content that are either display in a popover (desktop) or a drawer (mobile)
 * ex: Notifications, ProfileNavSection
 */
export const ResponsiveNavBarContent = ({
  contentType,
  isOpen,
  onOpen,
  onClose,
  children,
  contentAnchor,
  topOffset,
  minHeight,
  maxHeight,
}: ResponsiveNavBarContentProps) => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  // Common component props
  const commonProps = {
    contentType,
    isOpen,
    onClose,
  };

  return isMobile ? (
    <BottomNavBarDrawer
      {...commonProps}
      {...(onOpen && { onOpen })}
      {...(minHeight && { minHeight })}
      {...(maxHeight && { maxHeight })}
    >
      {children}
    </BottomNavBarDrawer>
  ) : (
    <TopNavBarPopover
      {...commonProps}
      {...(contentAnchor && { contentAnchor })}
      {...(topOffset && { topOffset })}
    >
      {children}
    </TopNavBarPopover>
  );
};
