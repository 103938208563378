import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Stack } from '@mui/material';

import { Video } from '../Video';
import { VideoComments } from '../VideoComments';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import i18next from '../../languages/i18n.config';
import { VideoDto } from '@keyops-hcp/dtos';
import { VideoDiscussion } from '../VideoDiscussion';

export const VideoWrapper = ({ videoContent }: { videoContent: VideoDto }) => {
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <Stack spacing={2}>
      <AdBoardSectionTaskInfo>
        {i18next.t('video.infoText')}
      </AdBoardSectionTaskInfo>
      <Video videoId={videoContent?.id} ref={playerRef} />
      {videoContent?.discussionId ? (
        <VideoDiscussion
          discussionId={videoContent?.discussionId}
          playerRef={playerRef}
        />
      ) : (
        <VideoComments videoId={videoContent?.id} ref={playerRef} />
      )}
    </Stack>
  );
};
