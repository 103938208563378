export const PharmacistPracticeSettings = [
  "community_retail",
  "hospital",
  "clinical_specialty",
  "long_term_care",
  "industry",
  "general_practice_primary_care",
  "distributor_wholesale",
  "consultant",
  "retail",
  "other",
] as const;

export type PharmacistPracticeSetting =
  (typeof PharmacistPracticeSettings)[number];
