import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Share = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipRule='evenodd' fill='currentColor' fillRule='evenodd'>
        <path d='m11.2929 2.29289c.3905-.39052 1.0237-.39052 1.4142 0l4 4c.3905.39053.3905 1.02369 0 1.41422-.3905.39052-1.0237.39052-1.4142 0l-3.2929-3.2929-3.29289 3.2929c-.39053.39052-1.02369.39052-1.41422 0-.39052-.39053-.39052-1.02369 0-1.41422z' />
        <path d='m12 2c.5523 0 1 .44772 1 1v12c0 .5523-.4477 1-1 1s-1-.4477-1-1v-12c0-.55228.4477-1 1-1z' />
        <path d='m5 12c.55228 0 1 .4477 1 1v5c0 1.1046.89543 2 2 2h8c1.1046 0 2-.8954 2-2v-5c0-.5523.4477-1 1-1s1 .4477 1 1v5c0 2.2091-1.7909 4-4 4h-8c-2.20914 0-4-1.7909-4-4v-5c0-.5523.44772-1 1-1z' />
      </g>
    </svg>
  </SvgIcon>
);
