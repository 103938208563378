import React, { RefObject } from 'react';
import ReactPlayer from 'react-player';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiLoader } from 'react-icons/fi';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import { keyframes } from '@mui/system';

import { usePostDiscussionMessage } from '../../hooks';
import { theme } from '../../theme';
import i18next from '../../languages/i18n.config';

interface DiscussionMessageFormProps {
  discussionId: string;
  playerRef?: RefObject<ReactPlayer | null>;
}

// Define the keyframes for the spin animation
const spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

export const DiscussionMessageForm = ({
  discussionId,
  playerRef,
}: DiscussionMessageFormProps): JSX.Element => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ message: string }>({
    defaultValues: {
      message: undefined,
    },
  });

  // Post one message (update the progress and invalidate the get all discussions in the mutation itself)
  const {
    mutate: postDiscussionMessage,
    isLoading,
    isError,
  } = usePostDiscussionMessage();

  const handlePostMessage: SubmitHandler<{ message: string }> = async (
    messageTosave
  ) => {
    if (playerRef) {
      const player = playerRef.current.getInternalPlayer();
      const currentTime = await player.getCurrentTime();
      postDiscussionMessage({
        ...messageTosave,
        discussionId,
        type: 'video',
        context: {
          videoTimeStamp: parseInt(`${currentTime}`, 10),
        },
      });
    } else {
      postDiscussionMessage({
        ...messageTosave,
        discussionId,
        type: 'discussion',
        context: {},
      });
    }
    reset();
  };

  const handleOnFocusEvent = () => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      player.pause();
    }
  };

  if (isError) return <>Something went wrong</>;

  return (
    <Box width={'100%'} alignSelf={'center'} pb={2}>
      <form
        onSubmit={handleSubmit(handlePostMessage)}
        onFocus={handleOnFocusEvent}
        style={{
          transition: 'opacity 0.5s ease-in-out', // Smooth form transitions when submitting
          opacity: isLoading ? 0.5 : 1, // Dim the form when loading
        }}
      >
        <FormGroup>
          <FormControl variant='outlined'>
            <OutlinedInput
              id='discussion-message-message-input'
              aria-describedby='discussion-message-message-input'
              placeholder={i18next.t('forms.helperMessage')}
              multiline
              fullWidth
              rows={3}
              maxRows={10}
              {...register('message')}
              sx={{
                paddingBottom: `8px`,
                position: 'relative',
                borderRadius: '8px',
                borderColor: theme.palette.keyops.gray[400],
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.keyops.blue.midnightBlue,
                    borderWidth: '1px',
                  },
                },
              }}
              endAdornment={
                <Button
                  type='submit'
                  variant='outlined'
                  sx={{
                    position: 'absolute',
                    right: '8px',
                    bottom: '8px',
                    color: theme.palette.keyops.blue.midnightBlue,
                    borderColor: theme.palette.keyops.blue.midnightBlue,
                    '&:hover': {
                      borderColor: theme.palette.keyops.blue.midnightBlue,
                      backgroundColor: theme.palette.keyops.blue.midnightBlue,
                      color: 'white',
                    },
                  }}
                  endIcon={
                    isLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          animation: `${spin} 3s linear infinite`, // Apply the spin animation
                        }}
                      >
                        <FiLoader />
                      </Box>
                    )
                  }
                >
                  {i18next.t('common.send')}
                </Button>
              }
            />

            {errors.message && (
              <FormHelperText>{errors.message.message}</FormHelperText>
            )}
          </FormControl>
        </FormGroup>
      </form>
    </Box>
  );
};
