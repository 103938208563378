import React from 'react';

import { TextInput } from 'react-admin';

export const EditModeratorProfile = () => {
  return (
    <>
      <h3>Moderator</h3>
      <TextInput fullWidth source='companyName' />
      <TextInput fullWidth source='jobTitle' />
    </>
  );
};
