import React, { ReactNode } from 'react';

import { useRecordContext } from 'react-admin';
import { baseMixpanelUrl } from '../data';
import Link from '@mui/material/Link';

export const ConditionalPhysicianFields = ({
  children,
}: {
  children: ReactNode;
}) => {
  const record = useRecordContext();
  if (!record || record.hcpType !== 'physician') return null;
  return <>{children}</>;
};

export const ConditionalPharmacistFields = ({
  children,
}: {
  children: ReactNode;
}) => {
  const record = useRecordContext();
  if (!record || record.hcpType !== 'pharmacist') return null;
  return <>{children}</>;
};

export const ConditionalNurseFields = ({
  children,
}: {
  children: ReactNode;
}) => {
  const record = useRecordContext();
  if (!record || record.hcpType !== 'nurse') return null;
  return <>{children}</>;
};

export const ConditionalModeratorFields = ({
  children,
}: {
  children: ReactNode;
}) => {
  const record = useRecordContext();
  if (!record || record.hcpType !== 'moderator') return null;
  return <>{children}</>;
};

export const MixPanelLink = () => {
  const record = useRecordContext();
  return <Link href={`${baseMixpanelUrl}${record.id}`}>Mixpanel Link</Link>;
};
