import { t } from 'i18next';
import { EarningEngagementsResponseDto } from '@keyops-hcp/dtos';

import { axiosCall } from '../utils/api';

export const getEarningsEngagements = async (
  userId: number
): Promise<EarningEngagementsResponseDto[]> => {
  try {
    const result = await axiosCall(false, 'get', `user/${userId}/get-earnings`);
    if (result.status >= 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.message || t('apiErrorMessages.no_survey_found')
    );
  }
};
