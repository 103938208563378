import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessageWrite = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C7.58172 4 4 7.58172 4 12C4 13.6921 4.52425 15.2588 5.41916 16.5503C5.71533 16.9778 5.78673 17.5484 5.55213 18.0532L4.64729 20H12C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22H3.86159C2.72736 22 2.00986 20.7933 2.53406 19.8016L3.62175 17.4613C2.59621 15.8909 2 14.0137 2 12Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99995 13.3C8.71792 13.3 9.29995 12.718 9.29995 12C9.29995 11.282 8.71792 10.7 7.99995 10.7C7.28198 10.7 6.69995 11.282 6.69995 12C6.69995 12.718 7.28198 13.3 7.99995 13.3Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 13.3C16.7179 13.3 17.3 12.718 17.3 12C17.3 11.282 16.7179 10.7 16 10.7C15.282 10.7 14.7 11.282 14.7 12C14.7 12.718 15.282 13.3 16 13.3Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 13.3C12.7179 13.3 13.3 12.718 13.3 12C13.3 11.282 12.7179 10.7 12 10.7C11.282 10.7 10.7 11.282 10.7 12C10.7 12.718 11.282 13.3 12 13.3Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);
