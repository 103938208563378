import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export const Rewards = (props: SvgIconProps & { iconBgColor?: string }) => {
  const { iconBgColor, ...svgProps } = props;
  const theme = useTheme();
  const bgColor = iconBgColor ?? theme.palette.keyops.blue.arcticBlue;
  return (
    <SvgIcon {...svgProps}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='12' cy='12' r='12' fill='currentColor' />
        <circle
          cx='12.0003'
          cy='12.0001'
          r='9.48077'
          stroke={bgColor}
          strokeWidth='0.5'
        />
        <path
          d='M12.5249 6.62965C12.4068 6.38586 12.1572 6.23071 11.883 6.23071C11.6089 6.23071 11.3615 6.38586 11.2411 6.62965L9.80802 9.56186L6.60749 10.0317C6.34003 10.0716 6.11715 10.2578 6.03469 10.5127C5.95222 10.7675 6.01909 11.049 6.21076 11.2374L8.53315 13.5225L7.98487 16.7517C7.9403 17.0176 8.05174 17.288 8.27239 17.4454C8.49304 17.6027 8.78501 17.6227 9.02572 17.4963L11.8852 15.9781L14.7448 17.4963C14.9855 17.6227 15.2774 17.6049 15.4981 17.4454C15.7187 17.2858 15.8302 17.0176 15.7856 16.7517L15.2351 13.5225L17.5575 11.2374C17.7492 11.049 17.8183 10.7675 17.7336 10.5127C17.6489 10.2578 17.4282 10.0716 17.1608 10.0317L13.958 9.56186L12.5249 6.62965Z'
          fill={bgColor}
        />
      </svg>
    </SvgIcon>
  );
};
