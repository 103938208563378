import React, { createContext, ReactNode, useContext } from 'react';
import { useIsAdminHook, useHcpAdBoardContext } from '../hooks';
import { useAdminAdBoard } from '../hooks/useAdminAdBoard';
import {
  AdBoardAttachmentSectionProgressDto,
  AdBoardDiscussionSectionProgressDto,
  AdBoardDto,
  AdBoardParticipantsDto,
  AdBoardProgressDto,
  AdBoardSectionGroupDto,
  AdBoardSectionProgressDto,
  AdBoardSurveySectionProgressDto,
  AdBoardVideoSectionProgressDto,
  SectionDto,
} from '@keyops-hcp/dtos';

export interface AdBoardContext {
  isLoading: boolean;
  isError: boolean;
  adBoardData?: AdBoardDto;
  sectionsData?: SectionDto[];
  sectionGroupsData?: AdBoardSectionGroupDto[];
  allParticipantsData?: AdBoardParticipantsDto[];
  userId?: number;
  adBoardProgress?: AdBoardProgressDto;
  currentSectionDetails?: SectionDto;
  currentSectionProgress?: AdBoardSectionProgressDto;
  role: 'admin' | 'participant' | 'moderator';
  updateProgress: (
    data:
      | AdBoardVideoSectionProgressDto
      | AdBoardAttachmentSectionProgressDto
      | AdBoardSurveySectionProgressDto
      | AdBoardDiscussionSectionProgressDto
  ) => void;
  refetchAllData?: () => void;
  isParticipantModerator?: (userId: number) => boolean;
  isSectionAccessible: (section: Pick<SectionDto, 'id'>) => boolean;
  isSectionFinished: (section: Pick<SectionDto, 'id'>) => boolean;
  //returns true if we should show the broader hcp nav, i.e. main hcp left nav
  shouldShowMainHcpNav: () => boolean;
  //return true if we should collect responses, e.g. in a survey
  shouldCollectResponses: () => boolean;
}

const hcpAdBoardContextDefaultValues: AdBoardContext = {
  isLoading: true,
  isError: false,
  role: 'participant',
  //HCP defaults closed
  isSectionAccessible: () => false,
  isSectionFinished: () => false,
  shouldShowMainHcpNav: () => true,
  //HCPs provide responses (e.g. fill out surveys)
  shouldCollectResponses: () => false, // Set to true when participant isn't moderator
  updateProgress: () => {},
};

const AdBoardContext = createContext<AdBoardContext>(
  hcpAdBoardContextDefaultValues
);

const adminAdBoardContextDefaultValues: AdBoardContext = {
  isLoading: true,
  isError: false,
  role: 'admin',
  //Admin defaults open
  isSectionAccessible: () => true,
  //Admins can't finish anything, so false
  isSectionFinished: () => false,
  shouldShowMainHcpNav: () => false,
  //Admins do not provide responses (e.g. fill out surveys)
  shouldCollectResponses: () => false,
  updateProgress: () => {},
};

const AdminAdBoardContext = createContext<AdBoardContext>(
  adminAdBoardContextDefaultValues
);

export const useAdBoardContext = (): AdBoardContext => {
  const isAdmin = useIsAdminHook();
  const context = useContext(isAdmin ? AdminAdBoardContext : AdBoardContext);
  return context;
};

/**
 * Used only to wrap physician-ui
 */
export const HCPAdBoardDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const data = useHcpAdBoardContext();

  return (
    <AdBoardContext.Provider value={data}>{children}</AdBoardContext.Provider>
  );
};

/**
 * Used only to wrap admin-ui
 */
export const AdminAdBoardDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const data = useAdminAdBoard();

  return (
    <AdminAdBoardContext.Provider value={data}>
      {children}
    </AdminAdBoardContext.Provider>
  );
};
