import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DiscussionDto } from '@keyops-hcp/dtos';
import { getOneDiscussion } from '../api/adBoardDiscussions';

export const useOneDiscussion = (
  discussionId: string
): UseQueryResult<DiscussionDto, Error> => {
  return useQuery({
    queryKey: ['getOneDiscussion', discussionId],
    queryFn: () => getOneDiscussion(discussionId),
    enabled: !!discussionId,
  });
};
