import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EarningEngagementsResponseDto } from '@keyops-hcp/dtos';

import { getEarningsEngagements } from '../api/earnings';

export const useEarningsByUserId = (
  userId: number
): UseQueryResult<EarningEngagementsResponseDto[], Error> => {
  return useQuery({
    queryKey: ['userId', userId],
    queryFn: () => getEarningsEngagements(userId),
    enabled: !!userId,
  });
};
