import React from 'react';
import { Box } from '@mui/material';

import { DiscussionDto } from '@keyops-hcp/dtos';
import { DiscussionMessage } from '../DiscussionMessage';
import { useChatScroll } from '../../utils/common-utils';

interface DiscussionChatMessagesProps {
  pageDisplay?: boolean;
  discussion: DiscussionDto;
}

export const DiscussionChatMessages = ({
  pageDisplay,
  discussion,
}: DiscussionChatMessagesProps): JSX.Element => {
  const ref = useChatScroll(discussion?.messages);

  return (
    <Box
      display={'flex'}
      flexDirection={pageDisplay ? 'column-reverse' : 'column'}
      gap={pageDisplay ? 2 : 0}
      alignContent={'flex-start'}
      ref={ref}
      flexGrow={1} // This allows the content to take up remaining space
      overflow='auto' // Enables scrolling for chat content
      p={'16px 0'}
      height={'100%'}
      sx={{
        // Since justifyContent={'flex-end'} Won't work with the scroll
        '> :first-of-type': {
          marginTop: 'auto !important',
          /* use !important to prevent breakage from child margin settings */
        },
      }}
    >
      {discussion &&
        discussion?.messages?.map((message) => (
          <DiscussionMessage key={message.id} message={message} />
        ))}{' '}
    </Box>
  );
};
