import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.keyops.black.main,
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.keyops.gray[700],
  },
  clickableBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: '#F3F9FC',
    borderRadius: '9px',
  },
  rowStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnStyle: {
    p: 1.5,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EDF5F9',
  },
});
