export const NurseOccupationsCA = [
  'nurse_practitioner',
  'registered_nurse',
  'registered_practical_nurse',
  'registered_psychiatic_nurse',
] as const;
export const NurseOccupationsUS = [
  'nurse_practitioner',
  'registered_nurse',
  'certified_nurse_midwife',
  'certified_registered_nurse_anesthetist',
  'doctor_of_nursing_practice',
  'licensed_practical_nurse',
  'other',
] as const;

export const NurseOccupations = [
  ...new Set([...NurseOccupationsCA, ...NurseOccupationsUS]),
] as const;

export type NurseOccupation = (typeof NurseOccupations)[number];
