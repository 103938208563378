import { DiscussionDto } from '@keyops-hcp/dtos';
import { t } from 'i18next';

import { axiosCall } from '../utils/api';
import { DISCUSSIONS_ENDPOINT } from '../utils/api-routes';

// TODO: add description
export const getOneDiscussion = async (
  discussionId: string
): Promise<DiscussionDto> => {
  try {
    const response = await axiosCall(
      false,
      'get',
      DISCUSSIONS_ENDPOINT + `/${discussionId}`
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(
      response?.data?.message || t('apiErrorMessages.no_discussion_found')
    );
  } catch (error) {
    console.error('Error fetching data getOneDiscussion:', error);
    throw new Error(
      error?.response?.data?.message ||
        t('apiErrorMessages.no_discussion_found')
    );
  }
};
