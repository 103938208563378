import { useEditContext } from 'react-admin';
import { useQuery, UseQueryResult } from '@keyops-hcp/ui-components';
import { AdBoardEngagementFeedDto } from '@keyops-hcp/dtos';

import { getAdBoardProgressFeed } from '../api/adboards-api';

const useProgressFeed = (): UseQueryResult<
  AdBoardEngagementFeedDto[],
  Error
> => {
  const { record: adBoardData } = useEditContext();

  return useQuery({
    queryKey: ['useProgressFeed', adBoardData?.id],
    queryFn: () => getAdBoardProgressFeed(adBoardData?.id),
    enabled: !!adBoardData?.id,
  });
};

export default useProgressFeed;
