import React from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  dateFormatter,
  getVideoTime,
} from '@keyops-hcp/ui-components/dist/utils/datetime';
import { AdBoardEngagementFeedDto } from '@keyops-hcp/dtos';

const FeedList = ({ data }: { data: AdBoardEngagementFeedDto[] }) => {
  if (data.length === 0) return <>No data</>;
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label='progress-feed-table'>
        <TableBody>
          {data?.map((progressFeedItem, i) => (
            <TableRow
              key={`${progressFeedItem.id}-${i}`}
              sx={{ td: { border: 0, px: 0, verticalAlign: 'top' } }}
            >
              <TableCell width={200}>
                <Typography variant='body2'>
                  {progressFeedItem?.participantName}
                </Typography>
                <Typography variant='body2' color='GrayText'>
                  {dateFormatter(progressFeedItem.createdAt.toString())}
                </Typography>
              </TableCell>
              <TableCell>
                <Stack direction={'row'} spacing={2}>
                  <Tooltip
                    placement='top'
                    title={`Section ${
                      progressFeedItem?.sectionGroupIndex + 1
                    }: ${progressFeedItem?.sectionGroupTitle}`}
                    arrow
                  >
                    <Typography
                      variant='body2'
                      color='GrayText'
                      sx={{
                        maxWidth: '35ch',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Section {progressFeedItem?.sectionGroupIndex + 1}:{' '}
                      {progressFeedItem?.sectionGroupTitle}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    placement='top'
                    title={progressFeedItem?.sectionTitle}
                    arrow
                  >
                    <Typography
                      variant='body2'
                      color='GrayText'
                      sx={{
                        maxWidth: '35ch',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {progressFeedItem?.sectionTitle}
                    </Typography>
                  </Tooltip>
                  {progressFeedItem?.videoTimeStamp && (
                    <Typography variant='body2' color='GrayText'>
                      {getVideoTime(progressFeedItem?.videoTimeStamp)}
                    </Typography>
                  )}
                </Stack>
                <Typography variant='body2' mt={1}>
                  {progressFeedItem.content}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeedList;
