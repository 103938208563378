import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { FiEdit3 } from 'react-icons/fi';

import i18next from '../../../../languages/i18n.config';
import { CallToActionBox } from '../CallToActionBox';

export const ContractPending = () => {
  const { t } = i18next;
  const theme = useTheme();
  return (
    <CallToActionBox
      icon={
        <FiEdit3
          style={{
            color: theme.palette.keyops.teal,
            fontSize: '70px',
            flexShrink: 0,
          }}
        />
      }
      title={t('ad_board.landing_page.cta.contract_pending.title')}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          color: theme.palette.keyops.gray[700],
        }}
      >
        {t('ad_board.landing_page.cta.contract_pending.copy')}
      </Typography>
    </CallToActionBox>
  );
};
