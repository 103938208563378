import React, { useEffect } from 'react';
import { forOwn } from 'lodash';
import { MessageDto, PhysicianDto, UserDto } from '@keyops-hcp/dtos';

import i18next from '../../src/languages/i18n.config';

export const getUrl = (route: string, params = {}) => {
  forOwn(params, (val, key) => {
    route = route.replace(`:${key}`, val);
  });
  return route;
};

// If initialsOnly is true, return the first letter of the admin's name
// TODO: tests on discussions component
export const formatAuthorName = (
  names: string[],
  initialsOnly = false
): string => {
  let formattedName = '';

  names.forEach((name) => {
    if (!name) {
      formattedName = i18next.t('common.na');
      return;
    }
    formattedName += initialsOnly ? name?.charAt(0)?.toUpperCase() : name;

    if (!initialsOnly) formattedName += ' ';
  });

  return formattedName.trim();
};

// To auto scroll to the last posted message
// TODO: adapt to make it broader
export const useChatScroll = (
  dep: MessageDto[]
): React.MutableRefObject<HTMLDivElement | undefined> => {
  const ref = React.useRef<HTMLDivElement>();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
};

export const getSpecialty = (userData: UserDto) => {
  if (userData.hcpType === 'physician') {
    const profile = userData.hcpProfile as PhysicianDto;
    return profile.specialty;
  }
  return [];
};
