import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../theme';

interface DiscussionDrawerHeaderProps {
  title: string;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
}

export const DiscussionDrawerHeader = ({
  title,
  toggleDiscussionDrawer,
}: DiscussionDrawerHeaderProps): JSX.Element => {
  return (
    <Box p={2} display={'flex'} justifyContent={'left'} alignItems={'center'}>
      <IconButton
        size='small'
        onClick={() => toggleDiscussionDrawer(false)}
        style={{ color: theme.palette.keyops.gray['600'] }}
      >
        <CloseIcon style={{ fontSize: 16 }} />
      </IconButton>
      <Typography pl={'16px'} variant='body1' fontWeight={500}>
        {title}
      </Typography>
    </Box>
  );
};
