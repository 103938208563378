import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilledSend = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='m18.61 2.64548c1.5848-.45527 3.0468 1.00676 2.5916 2.59157l-4.0231 14.00465c-.6706 2.3344-3.7881 2.778-5.0689.7212l-1.7758-2.8516c-.49118-.7887-.37225-1.8139.2869-2.473l3.7904-3.7904c.3911-.3912.3922-1.02433.0023-1.41417-.3898-.38984-1.0229-.38876-1.4141.00241l-3.79029 3.79036c-.65914.6591-1.6843.7781-2.47305.2869l-2.85184-1.7759c-2.05675-1.2808-1.6132-4.39832.7212-5.06892z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  </SvgIcon>
);
