import React from 'react';

import { EditPharmacistProfile } from './EditPharmacistProfile';
import { EditPhysicianProfile } from './EditPhysicianProfile';
import {
  ConditionalModeratorFields,
  ConditionalNurseFields,
  ConditionalPharmacistFields,
  ConditionalPhysicianFields,
} from './utils';
import { EditNurseProfile } from './EditNurseProfile';
import { EditModeratorProfile } from './EditModeratorProfile';

export const EditHcpProfile = () => {
  return (
    <>
      <ConditionalPhysicianFields>
        <EditPhysicianProfile />
      </ConditionalPhysicianFields>
      <ConditionalPharmacistFields>
        <EditPharmacistProfile />
      </ConditionalPharmacistFields>
      <ConditionalNurseFields>
        <EditNurseProfile />
      </ConditionalNurseFields>
      <ConditionalModeratorFields>
        <EditModeratorProfile />
      </ConditionalModeratorFields>
    </>
  );
};
