import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ReactPlayer from 'react-player';

import { addCommentForVideo } from '../../api/videoComments';
import { useVideoComments } from '../../hooks/useVideoComments';
import { VideoCommentsDisplay } from '../VideoCommentsDisplay';
import MentionsTextArea from '../MentionsTextArea';
import { useMentions } from '../../hooks/useMentions';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { analytics } from '../../utils/analytics';

interface User {
  id: string;
  name: string;
}
// TODO: this is just a mock array of users. Remove this once we connect this component with ad-boards.
const mockUsers: User[] = [
  { id: '1', name: 'alice' },
  { id: '2', name: 'alex' },
  { id: '3', name: 'akash' },
  { id: '5', name: 'bob' },
];

export const VideoComments = forwardRef<
  ReactPlayer,
  {
    videoId: string;
  }
>(({ videoId }, playerRef) => {
  const { videoComments, refetchComments, loading, error } =
    useVideoComments(videoId);
  const {
    inputValue,
    setInputValue,
    textareaRef,
    handleInputValueChange: mentionsHandleInputValueChange,
    showSuggestions,
    suggestions,
    handleSuggestionClick,
    renderMentionsText,
  } = useMentions(mockUsers);

  const theme = useTheme();

  const { userId } = useAdBoardContext(); // Necessary to avoid bug if admin
  const addComment = async () => {
    if (textareaRef.current) {
      const iframe = document.querySelector('iframe');
      if (iframe && playerRef && 'current' in playerRef && playerRef.current) {
        try {
          const player = playerRef.current.getInternalPlayer();
          const currentTime = await player.getCurrentTime();
          await addCommentForVideo({
            videoId,
            text: inputValue,
            timestamp: parseInt(`${currentTime}`, 10),
          });

          analytics.track(`AdBoard Progress Video Comment`, {
            currentTime,
            text: inputValue,
          });
          refetchComments();
          setInputValue(''); // Clear the textarea after adding the comment
        } catch (error) {
          console.error('Error getting current time:', error);
        }
      }
    }
  };

  const [currentUserCommentCount, setCurrentUserCommentCount] = useState(0);

  useEffect(() => {
    if (videoComments) {
      const count = videoComments.reduce((sum, videoComment) => {
        if (videoComment.userId === userId) {
          sum++;
        }
        return sum;
      }, 0);
      setCurrentUserCommentCount(count);
    } else {
      setCurrentUserCommentCount(0);
    }
  }, [videoComments, userId]);
  const handleInputValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      player.pause();
    }
    mentionsHandleInputValueChange(event);
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
            fontSize: '14px',
            my: 1,
          }}
        >
          {i18next.t('video.comments.instructions')}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            color: theme.palette.keyops.gray[600],
            fontSize: '14px',
            my: 1,
          }}
        >
          {i18next.t('video.comments.requiredCount', {
            count: currentUserCommentCount > 3 ? 3 : currentUserCommentCount,
          })}
        </Typography>
      </Box>
      <MentionsTextArea
        ref={textareaRef}
        inputValue={inputValue}
        handleInputValueChange={handleInputValueChange}
        showSuggestions={showSuggestions}
        suggestions={suggestions}
        handleSuggestionClick={handleSuggestionClick}
        onSave={addComment}
      />
      <Box mt={2}>
        <VideoCommentsDisplay
          videoComments={videoComments}
          renderMentionsText={renderMentionsText}
          loading={loading}
          error={error}
        />
      </Box>
    </Box>
  );
});

VideoComments.displayName = 'VideoComments';
