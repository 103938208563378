import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FiCheckCircle, FiCircle, FiMessageCircle } from 'react-icons/fi';
import { analytics } from '../../utils/analytics'; // include analytics on click

export const DiscussionToggle = ({
  isFinished,
  topicTitle,
  topicId,
  messageCount,
  sectionNavigate,
}: {
  isFinished: boolean;
  topicTitle: string;
  topicId: string;
  messageCount: number;
  sectionNavigate: (id?: string) => void;
}) => {
  const { palette } = useTheme();
  const handleClick = () => {
    analytics.track(`AdBoard Discussion Opened`, { topicTitle, topicId });
    sectionNavigate(topicId);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid',
        borderColor: palette.keyops.blue.lightSteelBlue20,
        borderRadius: '8px',
        padding: '24px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: palette.keyops.gray[100],
        },
      }}
      onClick={handleClick}
    >
      <Stack alignItems={'center'} flexDirection={'row'} gap={2}>
        {isFinished ? (
          <FiCheckCircle
            data-testid='check-circle-icon'
            fontSize={20}
            color={palette.keyops.teal}
          />
        ) : (
          <FiCircle
            data-testid='circle-icon'
            color={palette.keyops.blue.lightSteelBlue}
            fontSize={20}
          />
        )}
        <Typography
          sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '21px' }}
        >
          {topicTitle}
        </Typography>
      </Stack>
      <Stack alignItems={'center'} flexDirection={'row'} gap={2}>
        <Typography
          sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}
        >
          {messageCount}
        </Typography>
        <FiMessageCircle
          data-testid='message-icon'
          color={palette.keyops.blue.midnightBlue}
          fontSize={20}
        />
      </Stack>
    </Box>
  );
};
