import React, { useMemo } from 'react';
import {
  Button,
  useListContext,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { AdBoardDto, AdBoardInviteDto } from '@keyops-hcp/dtos';
import { useAdBoardContractById } from '../../../../custom-hooks/useAdBoardContractById';
import { useSendAdBoardInvite } from '../../../../custom-hooks/useSendAdBoardInvite';
import { AdBoardParticipantRole } from '@keyops-hcp/constants';

export const BulkSendInviteButton = ({
  role,
}: {
  role: AdBoardParticipantRole;
}) => {
  const { selectedIds, data } = useListContext<AdBoardInviteDto>();
  const notify = useNotify();
  const adBoard = useRecordContext<AdBoardDto>();
  const { mutate: sendAdBoardInvites } = useSendAdBoardInvite(notify);
  const { data: contract } = useAdBoardContractById(adBoard.id);

  //Need to make sure all selected invites can be sent
  const areAllSendable = useMemo(
    () =>
      ['planning', 'live'].includes(adBoard.state) &&
      //Contracting is required for participants, not used for moderators
      (role !== 'participant' || contract) &&
      data
        .filter((invite) => selectedIds.includes(invite.id))
        .every((invite) => invite.state === 'draft'),
    [selectedIds, data, contract]
  );

  const handleClick = () => {
    sendAdBoardInvites({ adBoardId: adBoard.id, selectedIds });
  };

  return (
    <Button
      label='Send Invite'
      onClick={handleClick}
      disabled={!areAllSendable}
      variant='contained'
    />
  );
};
