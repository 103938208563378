import React, { ChangeEvent, useCallback, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useEditContext } from 'react-admin';

import SummarySection from './SummarySection';
import useParticipantsByAdBoardId from '../../../../custom-hooks/useParticipantsByAdBoardId';
import EngagementDetailsSection from './EngagementDetailsSection';

const EngagementOverview = ({
  handleIndividualFeed,
}: {
  handleIndividualFeed: (value: number | null) => void;
}) => {
  const { record: adBoardData } = useEditContext();
  const {
    isLoading,
    isError,
    data: participantsData = [],
  } = useParticipantsByAdBoardId(adBoardData.id);

  const [filters, setFilters] = useState({});

  const clearFilters = () => setFilters({});
  const handleSearchChange = useCallback(
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>,
      field: string
    ) => {
      const { value } = e.target;

      setFilters((prev) => {
        if (!value) {
          // Remove the field from the filters if value is undefined, null, or empty
          const updatedFilters = { ...prev };
          delete updatedFilters[field];
          return updatedFilters;
        }
        return { ...prev, [field]: value };
      });
    },
    []
  );

  if (isError) return <>Something went wrong</>;

  return (
    <>
      <SummarySection
        filters={filters}
        isLoading={isLoading}
        adBoardData={adBoardData}
        participantsData={participantsData}
      />
      <EngagementDetailsSection
        handleIndividualFeed={handleIndividualFeed}
        adBoardData={adBoardData}
        participantsData={participantsData}
        filters={filters}
        handleSearchChange={handleSearchChange}
        clearFilters={clearFilters}
      />
    </>
  );
};

export default EngagementOverview;
