import { UserDto } from '@keyops-hcp/dtos';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getUserData } from '../api/user';

export const useUserData = (userId: number): UseQueryResult<UserDto, Error> => {
  return useQuery({
    queryFn: () => getUserData(),
    queryKey: ['getUserData'],
    enabled: !!userId,
  });
};
