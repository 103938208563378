/*
 *  We have two sets here, the first is HCPs which can access most functionality.
 *  We have some additional user types, captured in UserTypes, that can login but
 *  are not HCPs.
 *
 * NOTE this list also exists in the db function `is_user_hcp` and is used in check
 * constraints to limit behaviours, e.g. survey invites.  Any changes to this should
 * be added there
 */
export const HcpTypes = ['physician', 'pharmacist', 'nurse'] as const;

export type HcpType = (typeof HcpTypes)[number];

export const UserTypes = [...HcpTypes, 'moderator'] as const;

export type UserType = (typeof UserTypes)[number];
