import React from 'react';
import { Box, useTheme } from '@mui/material';
import { FiAlertCircle } from 'react-icons/fi';

import { BaseElementProps } from '../types';
import { KeyopsSubTitle1 } from '../Headers';

export const AdBoardSectionTaskInfo = ({
  children,
  ...props
}: BaseElementProps) => {
  const { palette } = useTheme();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={1.5}
      py={1}
      borderRadius={'8px'}
      sx={{ backgroundColor: palette.keyops.background }}
      {...props}
    >
      <FiAlertCircle
        data-testid='fi-alert-circle'
        fontSize={20}
        color={palette.keyops.blue.midnightBlue}
        style={{ transform: 'rotate(180deg)' }}
      />
      <KeyopsSubTitle1>{children}</KeyopsSubTitle1>
    </Box>
  );
};
