import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import i18next from '../../languages/i18n.config';

export const ModeratorChip = () => {
  const { palette } = useTheme();
  return (
    <Box
      data-testid='moderator-chip'
      sx={{
        px: 1,
        borderRadius: 1,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        backgroundColor: palette.keyops.blue.midnightBlue,
      }}
    >
      <Typography sx={{ color: palette.keyops.white.main }}>
        {i18next.t('ad_board.moderator_chip')}
      </Typography>
    </Box>
  );
};
