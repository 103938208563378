import { t } from 'i18next';
import { axiosCall } from '../utils/api';

export const getUserData = async (): Promise<string> => {
  try {
    const userResults = await axiosCall(false, 'get', 'user');

    if (userResults.status === 200) {
      return userResults.data.data.dataValues;
    }
    throw new Error(
      userResults?.data?.message || t('apiErrorMessages.no_user_found')
    );
  } catch (error) {
    console.error('Error fetching user data - getUserData:', error);
    throw new Error(
      error?.response?.data?.message || t('apiErrorMessages.no_user_found')
    );
  }
};
