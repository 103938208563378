import { AdBoardDto, AdBoardSectionGroupDto } from '@keyops-hcp/dtos';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import { useEditContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useSaveSectionGroup } from '../../../../custom-hooks/useSaveSectionGroup';

export const AddEditSectionGroup = ({
  sectionGroup,
  afterClose = () => {},
}: {
  sectionGroup?: AdBoardSectionGroupDto;
  afterClose?: (sectionGroup?: AdBoardSectionGroupDto) => void;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { record: adBoard, refetch } = useEditContext<AdBoardDto>();
  const { mutate: saveAdBoardSectionGroup } = useSaveSectionGroup(afterClose);

  if (!adBoard) {
    navigate('/AdBoards');
    return <></>;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    formJson.adBoardId = adBoard.id;
    if (sectionGroup) {
      //for existing
      formJson.id = sectionGroup.id;
    } else {
      //for new
      formJson.index = adBoard.sectionGroups.length.toString();
    }
    await saveAdBoardSectionGroup(formJson);
    await refetch();
    handleClose();
  };

  return (
    <>
      <Button
        variant={sectionGroup ? 'text' : 'outlined'}
        onClick={handleClickOpen}
        sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
        title={sectionGroup ? 'Edit section' : undefined}
      >
        {sectionGroup ? <EditIcon /> : 'Add section'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit,
        }}
      >
        <DialogTitle>
          {sectionGroup ? 'Edit section' : 'Add section'}
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            id='title'
            name='title'
            label='Title'
            type='text'
            fullWidth
            variant='outlined'
            defaultValue={sectionGroup?.title}
          />
          <TextField
            id='description'
            name='description'
            label='Description'
            type='text'
            fullWidth
            variant='outlined'
            multiline
            rows={4}
            defaultValue={sectionGroup?.description}
          />
          <TextField
            required
            id='estimatedTime'
            name='estimatedTime'
            label='Estimated Time (min)'
            type='number'
            fullWidth
            rows={4}
            variant='outlined'
            defaultValue={sectionGroup?.estimatedTime}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            sx={{ textTransform: 'none' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            type='submit'
            sx={{ textTransform: 'none' }}
          >
            {sectionGroup ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
