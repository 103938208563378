import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Avatar, Box, Stack, Typography, Link } from '@mui/material';

import { MessageDto } from '@keyops-hcp/dtos';
import { getMinutesAgoFromString } from '../../utils/datetime';
import { formatAuthorName } from '../../utils/common-utils';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { ModeratorChip } from '../ModeratorChip';
import { Loader } from '../Loader';
import { getVideoTime } from '../../utils/datetime';

interface DiscussionMessageProps {
  message: MessageDto;
}

export const DiscussionMessage = ({
  message,
}: DiscussionMessageProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isParticipantModerator, isLoading, isError } = useAdBoardContext();
  if (isLoading) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  const jumpToTime = (timestamp: number) => {
    if (timestamp !== undefined && timestamp !== null) {
      setSearchParams({ videoTime: timestamp.toString() });
    }
  };

  const isVideo = message.type === 'video';
  const isAdmin = !!message.adminId;
  const isModerator = isParticipantModerator(message.userId);

  return (
    <Box pb={`8px`}>
      {/* Author avatar */}
      <Stack pb={`8px`} direction={'row'} spacing={1}>
        <Avatar
          sx={{
            backgroundColor: `${
              isAdmin ? 'keyops.blue.midnightBlue' : 'keyops.gray.400'
            }`,
            color: 'white',
            width: '40px',
            height: '40px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          {formatAuthorName(
            isAdmin
              ? [message?.admin?.name]
              : [message?.user?.firstName, message?.user?.lastName],
            true
          )}
        </Avatar>
        {/* TODO: generate a profil pic with initials */}
        <Box>
          <Stack pb={`8px`} direction={'row'} spacing={2}>
            {/* TODO: format according hcpType */}
            <Typography fontWeight={500} fontSize={`16px`} lineHeight={`24px`}>
              {formatAuthorName(
                isAdmin
                  ? [message?.admin?.name]
                  : [message?.user?.firstName, message?.user?.lastName],
                false
              )}
            </Typography>
            {isModerator && <ModeratorChip />}
            {isVideo && (
              <Typography fontSize={'14px'}>
                <Link
                  component='button'
                  sx={{ textDecoration: 'none' }}
                  onClick={() => jumpToTime(message.context.videoTimeStamp)}
                >
                  {getVideoTime(message.context.videoTimeStamp)}
                </Link>
              </Typography>
            )}
            {/* TODO: format dates */}
            <Typography
              fontWeight={400}
              fontSize={`14px`}
              lineHeight={`24px`}
              color={'keyops.gray.600'}
            >
              {getMinutesAgoFromString(message.createdAt.toString())}
            </Typography>
          </Stack>
          <Box>
            <Typography>{message?.content}</Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
