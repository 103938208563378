import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { FiCheckCircle } from 'react-icons/fi';
import {
  AdBoardSurveySectionProgressCombined,
  NurseDto,
  PharmacistDto,
  PhysicianDto,
  SurveyAdBoardSectionDto,
} from '@keyops-hcp/dtos';
import i18next from '../../languages/i18n.config';
import { Survey } from '../Survey';
import { useSurveyUniqueLink } from '../../hooks/useSurveyUniqueLink';
import { useAdBoardContext } from '../../context';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { analytics } from '../../utils/analytics';
import { useUserData } from '../../hooks/useUserData';
import { getSpecialty } from '../../utils/common-utils';

export const SurveyWrapper = () => {
  const theme = useTheme();
  const {
    isLoading: contextLoading,
    isError: contextError,
    role,
    userId,
    currentSectionDetails,
    isSectionFinished,
    updateProgress,
    currentSectionProgress,
    shouldCollectResponses,
  } = useAdBoardContext();

  const { data: userData, isLoading, isError } = useUserData(userId);

  const [editAnswers, setEditAnswers] = useState<boolean>(false);

  // Ensure hooks are always called before returning
  const progressLink = useMemo(() => {
    return (currentSectionProgress as AdBoardSurveySectionProgressCombined)
      ?.uniqueLink;
  }, [currentSectionProgress]);

  const { data: uniqueLink } = useSurveyUniqueLink(
    (currentSectionDetails as SurveyAdBoardSectionDto)?.content?.surveyId,
    !progressLink // Conditionally enable fetch based on progressLink
  );

  // Use `useRef` to store a stable reference to the current link
  const linkRef = useRef<string | undefined>(progressLink || uniqueLink);

  // Update the ref whenever the `link` changes
  useEffect(() => {
    linkRef.current = progressLink || uniqueLink;
  }, [progressLink, uniqueLink]);

  // Conditionally update progress if we fetched a new uniqueLink
  useEffect(() => {
    if (!progressLink && uniqueLink) {
      analytics.track(`AdBoard Progress Survey`, { finished: false });
      updateProgress({ uniqueLink });
    }
  }, [uniqueLink, progressLink, updateProgress]);

  // Add event listener for survey completion message
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.data?.type === 'surveyCompleted' && linkRef.current) {
        setEditAnswers(false);
        analytics.track(`AdBoard Progress Survey`, { finished: true });
        updateProgress({ uniqueLink: linkRef.current });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [updateProgress]);

  const ssLink = useMemo(() => {
    let baseLink = `${
      progressLink || uniqueLink
    }?Contact_HcpId=${userId}&Contact_EngagementRole=${role}`;

    if (isLoading || isError) return baseLink;

    let practiceLicenseYear;
    let practiceSetting;
    let specialty;

    // Narrow down the type based on hcpType
    if (userData?.hcpType !== 'moderator' && !!userData?.hcpProfile) {
      const hcpProfile = userData.hcpProfile as
        | PhysicianDto
        | PharmacistDto
        | NurseDto;
      practiceLicenseYear = hcpProfile?.practiceLicenseYear;
      practiceSetting = hcpProfile?.practiceSetting;
      specialty = getSpecialty(userData);
    }

    baseLink += `&Contact_Gender=${userData?.gender}`;
    baseLink += `&Contact_PracticeLicenseYear=${practiceLicenseYear}`;
    baseLink += `&Contact_YearOfBirth=${userData?.yearOfBirth}`;
    baseLink += `&Contact_PracticeSetting=${practiceSetting}`;
    baseLink += `&Contact_ProvinceOfPractice=${userData?.provinceOfPractice}`;
    baseLink += `&Contact_Specialty=${specialty}`;

    return baseLink;
  }, [progressLink, uniqueLink, role, userId, userData, isLoading, isError]);

  // Handle loading and error states after hooks have been called
  if (contextLoading || isLoading) {
    return <div>{i18next.t('common.loading')}</div>;
  }

  if (contextError || isError) {
    return <div>{i18next.t('common.error')}</div>;
  }

  // Render a message for non participants
  if (!shouldCollectResponses()) {
    return <Typography>{i18next.t('survey.notParticipant')}</Typography>;
  }

  if (!currentSectionDetails || currentSectionDetails.type !== 'survey') {
    return <div>{i18next.t('common.error')}</div>;
  }

  // Use the link from progress or fallback to the fetched link
  const link = progressLink || uniqueLink;

  if (isSectionFinished(currentSectionDetails) && !editAnswers) {
    return (
      <Box
        display='flex'
        height={'70%'}
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        gap={2}
      >
        {/* Check Circle */}
        <Box
          sx={{
            width: { xs: 65, md: '114px' },
            height: { xs: 65, md: '114px' },
          }}
        >
          <FiCheckCircle color={theme.palette.keyops.teal} size='100%' />
        </Box>

        {/* Title */}
        <Typography fontWeight={600} fontSize={{ xs: 18, md: 24 }}>
          {i18next.t('survey.surveyComplete')}
        </Typography>

        {/* Button to Edit Answers */}
        <Button
          variant='outlined'
          onClick={() => {
            setEditAnswers(true);
          }}
        >
          {i18next.t('survey.editAnswers')}
        </Button>
      </Box>
    );
  }

  return (
    <>
      {isSectionFinished(currentSectionDetails) && (
        <AdBoardSectionTaskInfo>
          {i18next.t('survey.infoText')}
        </AdBoardSectionTaskInfo>
      )}
      <Box sx={{ mt: 1, flex: 1, position: 'relative' }}>
        {link && link.length > 0 && <Survey link={ssLink} />}
      </Box>
    </>
  );
};
