import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { BaseElementProps } from '../types';

export const StyledButton = styled(Button)`
  text-transform: none;
  backgroundcolor: theme.palette.keyops.blue.keyopsBlue50;
`;

export const PrimaryButton = ({ children, ...props }: BaseElementProps) => {
  return (
    <StyledButton variant='contained' disableElevation {...props}>
      {children}
    </StyledButton>
  );
};
