import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Plus = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipRule='evenodd' fill='currentColor' fillRule='evenodd'>
        <path d='m3 12c0-.5523.44772-1 1-1h16c.5523 0 1 .4477 1 1s-.4477 1-1 1h-16c-.55228 0-1-.4477-1-1z' />
        <path d='m12 3c.5523 0 1 .44772 1 1v16c0 .5523-.4477 1-1 1s-1-.4477-1-1v-16c0-.55228.4477-1 1-1z' />
      </g>
    </svg>
  </SvgIcon>
);
