import React from 'react';
import { Drawer, Stack, Divider } from '@mui/material';
import { useAdBoardContext } from '../../context';
import { GeneralDiscussionChat } from '../GeneralDiscussionChat';
import { DiscussionDrawerHeader } from '../DiscussionDrawerHeader';
import i18next from '../../languages/i18n.config';
import { useOneDiscussion } from '../../hooks/useDiscussion';

interface DiscussionsDrawerProps {
  discussionDrawerOpen: boolean;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  modalContainerRef?: React.RefObject<HTMLElement>; // Add this prop for the modal container
}

export const DiscussionsDrawer = ({
  discussionDrawerOpen,
  toggleDiscussionDrawer,
  modalContainerRef,
}: DiscussionsDrawerProps): JSX.Element => {
  // TODO: reintroduce mobile handling
  // Fetch general discussion
  const { adBoardData } = useAdBoardContext();
  const { data: generalDiscussion } = useOneDiscussion(
    adBoardData?.generalDiscussionId
  );

  const topOffset = '96px'; // We want the drawer to open below the top navbar
  return (
    <Drawer
      open={discussionDrawerOpen}
      onClose={() => {
        toggleDiscussionDrawer(false);
      }}
      anchor={'right'}
      transitionDuration={600}
      container={modalContainerRef?.current || undefined} // Pass the modal container here
      variant='temporary'
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
        top: topOffset,
      }}
      PaperProps={{
        sx: {
          top: topOffset,
        },
      }}
      data-testId='discussions-drawer'
    >
      {generalDiscussion && (
        <Stack
          height={`calc(100vh - ${topOffset})`} // Full viewport height minus the top offset
          width={'70vw'}
          bgcolor='background.paper'
          overflow='hidden'
          pb={2}
          data-testId='discussions-box'
          direction='column'
          justifyContent={'flex-start'}
        >
          <DiscussionDrawerHeader
            title={i18next.t('ad_board.discussion.generalDiscussion.title')}
            toggleDiscussionDrawer={toggleDiscussionDrawer}
          />
          <Divider />
          <GeneralDiscussionChat generalDiscussion={generalDiscussion} />
        </Stack>
      )}
    </Drawer>
  );
};
