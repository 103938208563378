import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Calendar = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipRule='evenodd' fill='currentColor' fillRule='evenodd'>
        <path d='m2 8c0-2.76142 2.23858-5 5-5h10c2.7614 0 5 2.23858 5 5v9c0 2.7614-2.2386 5-5 5h-10c-2.76142 0-5-2.2386-5-5zm5-3c-1.65685 0-3 1.34315-3 3v9c0 1.6569 1.34315 3 3 3h10c1.6569 0 3-1.3431 3-3v-9c0-1.65685-1.3431-3-3-3z' />
        <path d='m8 2c.55228 0 1 .44772 1 1v3c0 .55228-.44772 1-1 1s-1-.44772-1-1v-3c0-.55228.44772-1 1-1z' />
        <path d='m6 10c0-.55228.44772-1 1-1h10c.5523 0 1 .44772 1 1 0 .5523-.4477 1-1 1h-10c-.55228 0-1-.4477-1-1z' />
        <path d='m15 17c0-.5523.4477-1 1-1h1c.5523 0 1 .4477 1 1s-.4477 1-1 1h-1c-.5523 0-1-.4477-1-1z' />
        <path d='m16 2c.5523 0 1 .44772 1 1v3c0 .55228-.4477 1-1 1s-1-.44772-1-1v-3c0-.55228.4477-1 1-1z' />
      </g>
    </svg>
  </SvgIcon>
);
