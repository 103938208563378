import { AdBoardParticipantsDto } from '@keyops-hcp/dtos';
import { axiosCall } from '../utils/api';
import i18next from 'i18next';
import { AD_BOARDS_ENDPOINT } from '../utils/api-routes';

export const getAllAdBoardParticipants = async (
  adBoardId: string
): Promise<AdBoardParticipantsDto[]> => {
  try {
    const results = await axiosCall(
      false,
      'get',
      `${AD_BOARDS_ENDPOINT}/${adBoardId}/all-participants`,
      undefined,
      undefined,
      {}
    );
    if (results.status === 200) {
      return results.data.data || [];
    }
    throw new Error(
      results.data?.message || i18next.t('api.participants.errorFetching')
    );
  } catch (error) {
    throw new Error(
      error?.message || i18next.t('api.participants.errorFetching')
    );
  }
};
