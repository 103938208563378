import React from 'react';
import { Typography, useTheme } from '@mui/material';
import i18next from '../../../../languages/i18n.config';
import { Trans } from 'react-i18next';
import { CallToActionBox } from '../CallToActionBox';
import { FiXCircle } from 'react-icons/fi';

export const AdBoardDeclinedBox = () => {
  const theme = useTheme();
  const { t } = i18next;
  return (
    <CallToActionBox
      icon={
        <FiXCircle
          style={{
            color: theme.palette.keyops.gray[500],
            fontSize: '70px',
            flexShrink: 0,
          }}
        />
      }
      title={t('ad_board.landing_page.cta.declined.title')}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          color: theme.palette.keyops.gray[700],
        }}
      >
        <Trans
          t={t}
          i18nKey='components:ad_board.landing_page.cta.declined.copy'
          components={{
            supportLink: <a href='mailto:physiciansupport@keyops.io' />,
          }}
        ></Trans>
      </Typography>
    </CallToActionBox>
  );
};
