import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ClockCircle = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipRule='evenodd' fill='currentColor' fillRule='evenodd'>
        <path d='m4.92893 4.92893c1.80855-1.80855 4.30992-2.92893 7.07107-2.92893 2.7611 0 5.2625 1.12038 7.0711 2.92893 1.8085 1.80855 2.9289 4.30992 2.9289 7.07107 0 2.7611-1.1204 5.2625-2.9289 7.0711-1.8086 1.8085-4.31 2.9289-7.0711 2.9289-2.76115 0-5.26252-1.1204-7.07107-2.9289-1.80855-1.8086-2.92893-4.31-2.92893-7.0711 0-2.76115 1.12038-5.26252 2.92893-7.07107zm7.07107-.92893c-2.20942 0-4.20804.89434-5.65685 2.34315s-2.34315 3.44743-2.34315 5.65685c0 2.2094.89434 4.208 2.34315 5.6569 1.44881 1.4488 3.44743 2.3431 5.65685 2.3431 2.2094 0 4.208-.8943 5.6569-2.3431 1.4488-1.4489 2.3431-3.4475 2.3431-5.6569 0-2.20942-.8943-4.20804-2.3431-5.65685-1.4489-1.44881-3.4475-2.34315-5.6569-2.34315z' />
        <path d='m12 7c.5523 0 1 .44772 1 1v4c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4c0-.55228.4477-1 1-1z' />
        <path d='m11.2929 11.2929c.3905-.3905 1.0237-.3905 1.4142 0l2 2c.3905.3905.3905 1.0237 0 1.4142s-1.0237.3905-1.4142 0l-2-2c-.3905-.3905-.3905-1.0237 0-1.4142z' />
      </g>
    </svg>
  </SvgIcon>
);
