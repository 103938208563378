import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const StarCircle = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7148 7.19621C11.2978 6.22748 12.7022 6.22748 13.2852 7.19621L14.2842 8.85612L16.1715 9.29323C17.273 9.54833 17.707 10.884 16.9658 11.7378L15.6958 13.2008L15.8633 15.1309C15.9611 16.2573 14.8249 17.0828 13.7838 16.6418L12 15.886L10.2162 16.6418C9.17511 17.0828 8.03889 16.2573 8.13665 15.1309L8.30415 13.2008L7.03418 11.7378C6.29302 10.884 6.72701 9.54833 7.82849 9.29323L9.71584 8.85611L10.7148 7.19621ZM12 8.93934L11.3202 10.0689C11.1108 10.4169 10.7692 10.6651 10.3735 10.7567L9.08915 11.0542L9.95334 12.0497C10.2196 12.3565 10.3501 12.7581 10.315 13.1627L10.201 14.4761L11.4149 13.9618C11.7889 13.8034 12.2111 13.8034 12.5851 13.9618L13.799 14.4761L13.685 13.1627C13.6499 12.7581 13.7804 12.3565 14.0466 12.0497L14.9108 11.0542L13.6265 10.7567C13.2308 10.6651 12.8892 10.4169 12.6798 10.0689L12 8.93934Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);
