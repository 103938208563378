import React from 'react';

import { SimpleShowLayout, TextField } from 'react-admin';

export const ShowModeratorProfile = () => {
  return (
    <SimpleShowLayout>
      <h3>Moderator</h3>
      <TextField source='companyName' />
      <TextField source='jobTitle' />
    </SimpleShowLayout>
  );
};
