import { useMutation } from '@tanstack/react-query';
import {
  AdBoardDiscussionSectionProgressCombined,
  DiscussionMessageContextDto,
  MessageDto,
  VideoMessageContextDto,
} from '@keyops-hcp/dtos';

import { createOneDiscussionMessage } from '../api/discussionMessages';
import { queryClient } from '../wrappers';
import { useAdBoardContext } from '../context';
import { analytics } from '../utils/analytics';

// Define interfaces for each specific case
interface VideoMessageParams {
  message: string;
  discussionId: string;
  type: 'video';
  context: VideoMessageContextDto;
}

interface DiscussionMessageParams {
  message: string;
  discussionId: string;
  type: 'discussion';
  context: DiscussionMessageContextDto;
}

// Union type for the mutation parameters
type PostDiscussionMessageParams = VideoMessageParams | DiscussionMessageParams;

/**
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const usePostDiscussionMessage = () => {
  const { currentSectionProgress, updateProgress } = useAdBoardContext();

  return useMutation<MessageDto, unknown, PostDiscussionMessageParams>(
    async ({ message, discussionId, type, context }): Promise<MessageDto> => {
      const createdMessage = await createOneDiscussionMessage(
        message,
        discussionId,
        type,
        context
      );

      return createdMessage;
    },
    {
      onSuccess: (createdMessage, params) => {
        // Update the progress
        const existingFinishedTopics =
          (currentSectionProgress as AdBoardDiscussionSectionProgressCombined)
            ?.finishedTopics || [];
        if (
          !existingFinishedTopics.includes(createdMessage.discussionId) &&
          updateProgress
        ) {
          const finishedTopics = [
            ...existingFinishedTopics,
            createdMessage.discussionId,
          ];
          analytics.track(`AdBoard Progress Discussion`, { finishedTopics });
          updateProgress({
            finishedTopics,
          });
        }
        // Refetch the discussions query
        queryClient.invalidateQueries({
          queryKey: ['getOneDiscussion', params.discussionId],
        });
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};
