import React from 'react';

import { Avatar } from '@mui/material/';
import { UserType } from '@keyops-hcp/constants';

import { theme } from '../../theme';

type KeyOpsAvatarProps = {
  text: string;
  role?: UserType;
} & { [x: string]: any };

export const KeyOpsAvatar = ({ text, role, ...props }: KeyOpsAvatarProps) => {
  return (
    <Avatar
      sx={{
        width: props.width ?? 30,
        height: props.height ?? 30,
        backgroundColor:
          role === 'moderator'
            ? theme.palette.keyops.blue.midnightBlue
            : theme.palette.keyops.blue.lightSteelBlue,
        color: theme.palette.keyops.white.main,
        fontSize: 12,
        fontWeight: 500,
        ...props,
      }}
    >
      {text}
    </Avatar>
  );
};
