// The options here are meant to reflect identity, rather than biology.
// We have a "transgender" option here to support legacy values that can't be mapped directly to an identity
// A transgender person could identify as a man, a woman, or non-binary.
// Transgender is not included in onboarding, as, again, we're aonly asking about identity.
// This isn't to exclude anyone, it's that knowing whether someone is transgendered is more
// not related to identity.
export const Genders = [
  "woman",
  "man",
  "transgender",
  "non-binary",
  "no-response",
] as const;

export type Gender = (typeof Genders)[number];
