import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilledBarChart = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='m7 2c-2.76142 0-5 2.23858-5 5v10c0 2.7614 2.23858 5 5 5h10c2.7614 0 5-2.2386 5-5v-10c0-2.76142-2.2386-5-5-5zm6 6c0-.55228-.4477-1-1-1s-1 .44772-1 1v8c0 .5523.4477 1 1 1s1-.4477 1-1zm-5 2c.55228 0 1 .4477 1 1v5c0 .5523-.44771 1-1 1-.55228 0-1-.4477-1-1v-5c0-.5523.44772-1 1-1zm8 2c.5523 0 1 .4477 1 1v3c0 .5523-.4477 1-1 1s-1-.4477-1-1v-3c0-.5523.4477-1 1-1z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  </SvgIcon>
);
