import { Box } from '@mui/material/';
import React from 'react';
import { BaseElementProps } from '../types';

export const MiddleValign = ({ children, ...props }: BaseElementProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} {...props}>
      {children}
    </Box>
  );
};
