import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const RightArrow = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='m13.2929 4.29289c.3905-.39052 1.0237-.39052 1.4142 0l7 7.00001c.3905.3905.3905 1.0237 0 1.4142l-7 7c-.3905.3905-1.0237.3905-1.4142 0s-.3905-1.0237 0-1.4142l5.2929-5.2929h-15.5858c-.55228 0-1-.4477-1-1s.44772-1 1-1h15.5858l-5.2929-5.29289c-.3905-.39053-.3905-1.02369 0-1.41422z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  </SvgIcon>
);
