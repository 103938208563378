import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { createOneAdBoardInvite } from '../../../../api/adboard-invites-api';
import { useEditContext } from 'react-admin';
import { AdBoardParticipantRole } from '@keyops-hcp/constants';

export const CreateAdBoardInviteDialog = ({
  role,
  onSave = () => {},
}: {
  role: AdBoardParticipantRole;
  onSave?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { record: adBoard } = useEditContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant='outlined' onClick={handleClickOpen} size='medium'>
        Add {role}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            formJson.adBoardId = adBoard.id;
            formJson.role = role;
            await createOneAdBoardInvite(formJson);
            onSave();
            handleClose();
          },
        }}
      >
        <DialogTitle>Add {role}</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          {role === 'moderator' && (
            <Typography>
              If the email you enter is not in network, an out of network invite
              will be sent. This will only allow the user to create an HCP
              account, but not a moderator account. If you want to create a
              moderator account, add them as a user in the admin and then add
              them to the ad board.
            </Typography>
          )}
          <TextField
            required
            id='email'
            name='email'
            label='Email Address'
            type='email'
            fullWidth
            variant='outlined'
          />
          <TextField
            required
            id='firstName'
            name='firstName'
            label='First Name'
            type='text'
            fullWidth
            variant='outlined'
          />
          <TextField
            required
            id='lastName'
            name='lastName'
            label='Last Name'
            type='text'
            fullWidth
            variant='outlined'
          />
          {role === 'participant' && (
            <TextField
              required
              id='honorarium'
              name='honorarium'
              label='Honorarium'
              type='number'
              fullWidth
              variant='outlined'
              InputProps={{ inputProps: { min: 0, max: 10000 } }}
            />
          )}
          {role === 'participant' && (
            <TextField
              required
              id='rewards'
              name='rewards'
              label='Rewards'
              type='number'
              fullWidth
              variant='outlined'
              InputProps={{ inputProps: { min: 0, max: 10000 } }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' type='submit'>
            Add {role}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
