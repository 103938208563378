import React from 'react';

import { BooleanField, SimpleShowLayout, TextField } from 'react-admin';

export const ShowPhysicianProfile = () => {
  return (
    <SimpleShowLayout>
      <h3>Physician</h3>
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Practice
      </h4>
      <TextField source='specialty' />
      <TextField source='subSpecialty' />
      <TextField source='practiceSetting' />
      <BooleanField source='involvementTrials' />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        License
      </h4>
      <TextField source='licenseNumber' />
      <TextField source='practiceLicenseYear' />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Work Email
      </h4>
      <TextField source='workEmail' />
      <BooleanField source='workEmailVerified' />
    </SimpleShowLayout>
  );
};
