import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import { AdBoardSectionGroupDto, SectionDto } from '@keyops-hcp/dtos';

import i18next from '../../languages/i18n.config';
import { AdBoardSectionDisplay } from '../AdBoardSectionDisplay';
import { KeyopsHeader2, KeyopsSubTitle1, KeyopsSubTitle2 } from '../Headers';
import { AdBoardCompletedTaskButton } from '../AdBoardCompletedTaskButton';
import { useAdBoardContext } from '../../context';
import { AdBoardFinishedCard } from '../AdBoardFinishedCard';
import { getNextSectionType } from '../../utils/adboard-sections.utils';
import { Loader } from '../Loader';

const TitleSection = ({
  sectionGroups,
  currentSectionGroup,
  currentSection,
}: {
  sectionGroups: AdBoardSectionGroupDto[];
  currentSectionGroup: AdBoardSectionGroupDto;
  currentSection: SectionDto;
}) => {
  return (
    <Stack spacing={1} mb={2}>
      <KeyopsSubTitle2>
        {i18next.t('ad_board.sections_view.section_number', {
          currentSection: currentSectionGroup?.index + 1,
          totalSections: sectionGroups.length,
        })}{' '}
        | {currentSectionGroup?.title}
      </KeyopsSubTitle2>
      <KeyopsHeader2 fontSize={18}>{currentSection.title}</KeyopsHeader2>
      {currentSection.description && (
        <KeyopsSubTitle1>{currentSection.description}</KeyopsSubTitle1>
      )}
    </Stack>
  );
};

export const AdBoardSectionsToggle = ({
  currentSection,
  setCurrentSectionId,
  currentContentId,
}: {
  currentSection: SectionDto;
  setCurrentSectionId: (id: string, contentId?: string) => void;
  currentContentId?: string;
}) => {
  const [fullScreenData, setFullScreenData] = useState(false);
  const setIsFullScreenData = (isFullScreen: boolean) =>
    setFullScreenData(isFullScreen);

  const {
    isLoading,
    isError,
    adBoardData,
    sectionGroupsData: sectionGroups,
    sectionsData: sections,
    isSectionFinished, // Provide default values to avoid errors
  } = useAdBoardContext();

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  useEffect(() => {
    if (sections && sections.length > 0 && currentSection) {
      setCurrentSectionId(currentSection.id, currentContentId);
      setCurrentSectionIndex(
        sections.findIndex((section) => section.id === currentSection.id)
      );
    }
  }, [sections, currentSection]);

  const [currentSectionGroup, setCurrentSectionGroup] =
    useState<AdBoardSectionGroupDto>();

  useEffect(() => {
    if (sectionGroups && sectionGroups.length > 0 && currentSection) {
      const sectionGroup = sectionGroups.find(
        (sectionGroup) => sectionGroup.id === currentSection.sectionGroupId
      );
      setCurrentSectionGroup(sectionGroup);
    }
  }, [sectionGroups, sections, currentSection]);

  if (isLoading || !currentSection) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  return (
    <>
      {!fullScreenData && (
        <TitleSection
          sectionGroups={sectionGroups}
          currentSectionGroup={currentSectionGroup}
          currentSection={currentSection}
        />
      )}
      <AdBoardSectionDisplay
        section={currentSection}
        setIsFullScreenData={setIsFullScreenData}
        setCurrentSectionId={setCurrentSectionId}
        currentContentId={currentContentId}
      />
      {!fullScreenData &&
        isSectionFinished(currentSection) &&
        // If not last section
        (currentSectionIndex !== sections.length - 1 ? (
          <AdBoardCompletedTaskButton
            onClick={() => {
              const nextSection = sections[currentSectionIndex + 1];
              setCurrentSectionId(nextSection.id);
            }}
            buttonContent={
              <>
                {getNextSectionType(sectionGroups, currentSection.id) ===
                'section'
                  ? i18next.t('ad_board.completed_task_button.next_section')
                  : i18next.t('ad_board.completed_task_button.next_activity')}
                <FiArrowRight />
              </>
            }
          />
        ) : (
          <AdBoardFinishedCard endDate={adBoardData?.endDate} />
        ))}
    </>
  );
};
