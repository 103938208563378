export const UserStates = [
  "new",
  "onboarding",
  "verified",
  "out_of_network",
  "verify_profile",
  "profile_complete",
  "disabled",
] as const;

export type UserState = (typeof UserStates)[number];
