// for the following 2 occupations: Nurse Practitioner (NP) & Doctor of Nursing Practice (DNP)
// Show “Specialty” dropdown with these options:
export const PartialNurseSpecialties = [
  'acute_care',
  'adult_health',
  'family',
  'general_nursing',
  'geriatric_nursing', // this is the only difference
  'neonatal_perinatal_nursing',
  'occupational_health',
  'pediatric_nursing',
  'psychiatric_mental_health',
  'womens_health',
  'other',
] as const;

// for the following occupation: Registered Nurse (RN)
// Show “Specialty” dropdown with these options:
export const FullNurseSpecialties = [
  'acute_care',
  'adult_health',
  'allergy_immunology',
  'anesthesiology',
  'cardiology',
  'community_health',
  'critical_care',
  'dermatology',
  'emergency_medicine',
  'endocrinology',
  'family',
  'gastroenterology',
  'general_nursing',
  'gerontology',
  'hematology',
  'infectious_disease',
  'internal_medicine',
  'medical_genetics',
  'neonatal_perinatal_nursing',
  'nephrology',
  'neurology',
  'neurosurgery',
  'obstetrics_gynecology',
  'occupational_health',
  'oncology',
  'ophthalmology',
  'orthopedic_surgery',
  'otolaryngology_ent',
  'pathology',
  'pediatric_nursing',
  'physical_medicine_rehab',
  'plastic_surgery',
  'psychiatric_mental_health',
  'pulmonology',
  'radiation_oncology',
  'radiology',
  'rheumatology',
  'surgery',
  'thoracic_surgery',
  'urology',
  'womens_health',
  'other',
] as const;

export const NurseSpecialties = [
  ...new Set([...PartialNurseSpecialties, ...FullNurseSpecialties]),
] as const;
//currently based on US RN nurses options, might need to be fiddled with

export type NurseSpecialty = (typeof NurseSpecialties)[number];
