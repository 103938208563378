import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
  styled,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { AttachmentFormDto } from '@keyops-hcp/dtos';
import { AttachmentDto } from '@keyops-hcp/dtos';
import { createOneAttachment, getAllAttachments } from '../api/attachments';

// TODO: rm as it is not used
type AttachmentsFormType = {
  attachment: AttachmentDto | undefined;
  setAllAttachments: React.Dispatch<React.SetStateAction<AttachmentDto[]>>;
  setAttachment: React.Dispatch<
    React.SetStateAction<AttachmentDto | undefined>
  >;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const CreateAttachmentForm = ({
  attachment,
  setAllAttachments,
  setAttachment,
  setErrorMessage,
  setSuccessMessage,
  setIsModalOpen,
  setIsToastOpen,
}: AttachmentsFormType) => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AttachmentFormDto>({
    defaultValues: {
      title: attachment?.title,
      description: attachment?.description,
      readingTime: attachment?.readingTime,
    },
  });

  // State handling
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handle the file name preview after uploading
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState<
    string | null
  >(null);

  const handleCreateAttachment: SubmitHandler<AttachmentFormDto> = async (
    attachmentToCreate
  ) => {
    try {
      setIsLoading(true);

      const results = await createOneAttachment(attachmentToCreate);

      if (results) {
        setAttachment(results);
        setSuccessMessage(`Attachment successfully uploaded!`);
        setIsToastOpen(true);
        // Refresh the attachments lists before closing the modal
        const updatedAttachmentsList = await getAllAttachments();
        if (updatedAttachmentsList) setAllAttachments(updatedAttachmentsList);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message ?? `Error uploading the attachment`);
    } finally {
      setIsLoading(false);

      closeModalWithDelay();
    }
  };

  const closeModalWithDelay = () => {
    // Add a delay of 2 seconds (2000 milliseconds)
    setTimeout(() => {
      setIsModalOpen(false);
    }, 2000);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
    } else {
      setUploadFileErrorMessage(`Error uploading the file`);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateAttachment)}>
      <FormGroup>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <FormControl>
            {/* Title */}
            <TextField
              label='Title'
              id='attachment-title-input'
              aria-describedby='attachment-title-input'
              variant='outlined'
              fullWidth
              {...register('title', { required: true })}
              sx={{ paddingBottom: `8px` }}
            />
            {errors.title && (
              <FormHelperText>{errors.title.message}</FormHelperText>
            )}

            {/* Description */}
            <TextField
              label='Description'
              id='attachment-description-input'
              aria-describedby='attachment-description-input'
              variant='outlined'
              multiline
              fullWidth
              maxRows={4}
              {...register('description')}
              sx={{ paddingBottom: `8px` }}
            />

            {errors.description && (
              <FormHelperText>{errors.description.message}</FormHelperText>
            )}

            {/* Reading time */}
            {/* TODO: MUI does not recommend the use of type="number" and announced a NumberInput will be added - To follow
cf https://mui.com/material-ui/react-text-field/#type-quot-number-quot */}
            <Stack direction={'row'}>
              <TextField
                label='Reading time'
                type='number'
                id='attachment-reading-time-input'
                aria-describedby='attachment-reading-time-input'
                variant='outlined'
                {...register('readingTime', { required: true })}
                sx={{ paddingBottom: `8px`, width: `15vw` }}
              />
              <FormHelperText>Reading time, in minutes</FormHelperText>
            </Stack>
            {errors.readingTime && (
              <FormHelperText>{errors.readingTime.message}</FormHelperText>
            )}

            <Button
              component='label'
              variant='outlined'
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{ paddingBottom: `8px` }}
            >
              Upload file
              <VisuallyHiddenInput
                type='file'
                {...register('file', { required: true })}
                onChange={(e) => {
                  handleFileChange(e); // Handle the file change
                }}
              />
            </Button>
            <FormHelperText sx={{ m: 0, p: '8px 0' }}>
              Attachments must be a PDF, a zip or a Microsoft Office file
            </FormHelperText>

            {fileName && (
              <FormHelperText>Uploaded file: {fileName}</FormHelperText>
            )}
            {uploadFileErrorMessage && (
              <FormHelperText color='red'>
                {uploadFileErrorMessage}
              </FormHelperText>
            )}

            <Button type='submit' variant='contained' sx={{ marginTop: '8px' }}>
              Create
            </Button>
          </FormControl>
        )}
      </FormGroup>
    </form>
  );
};
