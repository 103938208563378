import React from 'react';
import { SectionDto } from '@keyops-hcp/dtos';

import { VideoWrapper } from '../VideoWrapper';
import { AttachmentList } from '../AttachmentList';
import { SurveyWrapper } from '../SurveyWrapper';
import { DiscussionList } from '../DiscussionList';

export const AdBoardSectionDisplay = ({
  section,
  setIsFullScreenData,
  setCurrentSectionId,
  currentContentId,
}: {
  section: SectionDto;
  setIsFullScreenData: (isFullScreen: boolean) => void;
  setCurrentSectionId: (id: string, contentId?: string) => void;
  currentContentId?: string;
}) => {
  const sectionNavigate = (contentId?: string) => {
    setCurrentSectionId(section.id, contentId);
  };

  switch (section.type) {
    case 'video':
      return <VideoWrapper videoContent={section?.content?.video} />;
    case 'attachment':
      return (
        <AttachmentList
          attachmentIds={section?.content.map(
            (attachmentContent) => attachmentContent.attachmentId
          )}
          setIsFullScreenData={setIsFullScreenData}
        />
      );
    case 'discussion':
      return (
        <DiscussionList
          content={section.content}
          setIsFullScreenData={setIsFullScreenData}
          sectionNavigate={sectionNavigate}
          topicId={currentContentId}
        />
      );
    case 'survey':
      return <SurveyWrapper />;
  }
  //TODO: may need this later
  // return (
  //   <Container>
  //     <Typography>unrecognized section type ${section.type}</Typography>
  //   </Container>
  // );
};
