import React, { ChangeEvent } from 'react';
import { useEditContext } from 'react-admin';
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AdBoardDto } from '@keyops-hcp/dtos';

interface FilterSelectProps {
  id: string;
  field: string;
  value: string;
  label: string;
  items: { id: string; index: number; title: string }[];
  onChange: (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    field: string
  ) => void;
}

const FilterSelect = ({
  id,
  field,
  value,
  label,
  items,
  onChange,
}: FilterSelectProps) => (
  <Box width={200} display={'inline-block'}>
    <FormControl fullWidth>
      <Select
        id={id}
        size='small'
        value={value}
        displayEmpty
        renderValue={(selected) => {
          // console.log('selected');
          // console.log(selected);
          return !selected ? (
            <Typography variant='body1' color='GrayText'>
              {label}
            </Typography>
          ) : (
            items.find((item) => item.id === selected)?.title || selected
          );
        }}
        onChange={(e) => onChange(e, field)}
      >
        <MenuItem>
          <Typography variant='body1' color='GrayText'>
            <em>{label}</em>
          </Typography>
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Typography
              sx={{
                maxWidth: '35ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

interface FeedFiltersProps {
  filters: { [key: string]: string };
  onSearchChange: (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    field: string
  ) => void;
}

const FeedFilters = ({ filters, onSearchChange }: FeedFiltersProps) => {
  const { record: adBoardData } = useEditContext<AdBoardDto>();

  return (
    <Box width={'100%'} display={'flex'} gap={2.5}>
      <FormControl sx={{ width: '35ch' }} variant='outlined'>
        <OutlinedInput
          id='search-feed'
          placeholder='Search'
          size='small'
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
          onChange={(e) => onSearchChange(e, 'q')}
        />
      </FormControl>

      <FilterSelect
        id='section-simple-select'
        field={'sectionGroupId'}
        value={filters?.sectionGroupId}
        label='Section'
        items={adBoardData?.sectionGroups ?? []}
        onChange={onSearchChange}
      />
      <FilterSelect
        id='activity-simple-select'
        field={'sectionId'}
        value={filters?.sectionId}
        label='Activity'
        items={adBoardData?.sections ?? []}
        onChange={onSearchChange}
      />
    </Box>
  );
};

export default FeedFilters;
