export const NurseHospitalSettings = ["inpatient", "outpatient"] as const;
export type NurseHospitalSetting = (typeof NurseHospitalSettings)[number];

export const NurseInpatientHospitalDepartments = [
  "inpatient-icu",
  "inpatient-nicu",
  "inpatient-med_surg", //what is this?  abbrevs unhelpful?
  "inpatient-oncology",
  "inpatient-pre-op_post-op",
  "inpatient-or",
  "inpatient-pediatrics",
  "inpatient-radiology_ir", // different from diagnostic_imaging ?
  "inpatient-rehabilitation",
  "inpatient-cardiology",
  "inpatient-mental_health", //psychiatry dept?
  "inpatient-other",
] as const;
export type NurseInpatientHospitalDepartment =
  (typeof NurseInpatientHospitalDepartments)[number];

export const NurseOutpatientHospitalDepartments = [
  "outpatient-ed_ambulatory_care", // ed unclear?  Emergency dept?
  "outpatient-infusion_clinic",
  "outpatient-dialysis",
  "outpatient-diagnostic_imaging",
  "outpatient-fracture_plastics", //plastics as in plastic surgery?  should it be the same option as fracture?  What about burns?
  "outpatient-orthopedic",
  "outpatient-oncology",
  "outpatient-or",
  "outpatient-pre-op_post-op",
  "outpatient-musculoskeletal", //same as ortho and fracture?
  "outpatient-rheumatology",
  "outpatient-endocrinology",
  "outpatient-cardiology",
  "outpatient-gastroenterology", //missing derm, maternity, ostomy and phlebotomy?
  "outpatient-rehabilitation",
  "outpatient-other",
] as const;
export type NurseOutpatientHospitalDepartment =
  (typeof NurseOutpatientHospitalDepartments)[number];

export const NurseCareTasks = [
  "home_infusion",
  "rehabilitation",
  "neuromuscular_disease_management",
  "other",
] as const;
export type NurseCareTask = (typeof NurseCareTasks)[number];

export const NurseClinicTypes = [
  "primary_care",
  "infusion_clinic",
  "dialysis_clinic",
  "neurology_neuromuscular_clinic",
  "oncology_clinic",
  "other",
] as const;
export type NurseClinicType = (typeof NurseClinicTypes)[number];

export const NursePracticeSettingDetails = [
  ...NurseHospitalSettings,
  ...NurseInpatientHospitalDepartments,
  ...NurseOutpatientHospitalDepartments,
  ...NurseCareTasks,
  ...NurseClinicTypes,
] as const;

export type NursePracticeSettingDetail =
  (typeof NursePracticeSettingDetails)[number];
