import React from 'react';
import { Button, Typography, useTheme, Stack } from '@mui/material';
import i18next from '../../../../languages/i18n.config';
import { useStyles } from './styles';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { CallToActionBox } from '../CallToActionBox';
import {
  useAcceptAdBoardInviteMutation,
  useDeclineAdBoardInviteMutation,
} from '../../../../hooks';
import { AdBoardParticipantRole } from '@keyops-hcp/constants';

export const AdBoardInviteChoiceBox = ({
  adBoardId,
  role,
}: {
  adBoardId: string;
  role: AdBoardParticipantRole;
}) => {
  const { t } = i18next;
  const theme = useTheme();
  const styles = useStyles(theme);
  const acceptAdBoardInviteMutation = useAcceptAdBoardInviteMutation();
  const declineAdBoardInviteMutation = useDeclineAdBoardInviteMutation();

  const acceptInvite = () => {
    acceptAdBoardInviteMutation.mutate(adBoardId);
  };
  const declineInvite = () => {
    declineAdBoardInviteMutation.mutate(adBoardId);
  };

  return (
    <CallToActionBox title={t('ad_board.landing_page.cta.invited.title')}>
      <Typography sx={styles.helperText}>
        {t('ad_board.landing_page.cta.invited.helperText.' + role)}
      </Typography>
      <Stack
        direction='row'
        sx={{ justifyContent: 'space-evenly', flexWrap: 'wrap' }}
      >
        <Button variant='text' onClick={acceptInvite} sx={styles.buttonAccept}>
          <FiCheckCircle style={{ fontSize: '70px' }} />
          <Typography sx={styles.buttonText}>
            {t('ad_board.landing_page.cta.invited.accept')}
          </Typography>
        </Button>
        <Button
          variant='text'
          onClick={declineInvite}
          sx={styles.buttonDecline}
        >
          <FiXCircle style={{ fontSize: '70px' }} />
          <Typography sx={styles.buttonText}>
            {t('ad_board.landing_page.cta.invited.decline')}
          </Typography>
        </Button>
      </Stack>
    </CallToActionBox>
  );
};
