import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  mainContainer: {
    pt: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  detailsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: '8px',
    backgroundColor: '#F3F9FC',
    padding: 3,
    flexWrap: 'wrap', // Allow items to wrap to a new line
    gap: '16px', // Define a gap between the items
    minWidth: '200px', // Minimum width for each child item to prevent squishing
  },
  detailsInfo: { display: 'flex', alignItems: 'center', gap: 1 },
  estimatedTimeToolTip: {
    padding: 0,
    color: theme.palette.keyops.blue.midnightBlue,
    height: '20px',
    width: '20px',
    '&:hover': {
      svg: {
        color: theme.palette.keyops.gray[400],
      },
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '28px',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
    color: theme.palette.keyops.black.main,
  },
  iconStyle: {
    color: theme.palette.keyops.blue.midnightBlue,
    width: '29.29px',
    height: '29.29px',
  },
  date: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '34.74px',
  },
  description: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
  },
  inviteDetails: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '27.56px',
  },
  content: {
    pt: 3,
    display: 'block',
    width: '90%',
    margin: '0 auto',
  },
});
