import React from 'react';

import {
  BooleanInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  email,
} from 'react-admin';
import {
  NurseEmploymentStatusChoices,
  NurseOccupationChoices,
  NursePracticeSettingChoices,
  NursePracticeSettingDetailChoices,
  NurseSpecialtyChoices,
} from '../../../utils/choices';

export const EditNurseProfile = () => {
  return (
    <>
      <h3>Nurse</h3>
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Practice
      </h4>
      <SelectInput
        fullWidth
        source='occupation'
        choices={NurseOccupationChoices}
      />
      <TextInput fullWidth source='otherOccupation' />
      <SelectInput
        fullWidth
        source='practiceSetting'
        choices={NursePracticeSettingChoices}
      />
      <TextInput fullWidth source='otherPracticeSetting' />
      <SelectArrayInput
        fullWidth
        allowEmpty
        source='specialty'
        choices={NurseSpecialtyChoices}
      />
      <SelectInput
        fullWidth
        source='practiceSettingDetail'
        choices={NursePracticeSettingDetailChoices}
      />
      <TextInput fullWidth source='otherPracticeSettingDetail' />
      <SelectInput
        fullWidth
        source='employmentStatus'
        choices={NurseEmploymentStatusChoices}
      />
      <TextInput fullWidth source='otherDetails' />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        License
      </h4>
      <NumberInput source='licenseNumber' fullWidth />
      <NumberInput source='practiceLicenseYear' fullWidth />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Work Email
      </h4>
      <TextInput source='workEmail' validate={email()} fullWidth />
      <BooleanInput label='Work Email Verified?' source='workEmailVerified' />
    </>
  );
};
