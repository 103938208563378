import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mail = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipRule='evenodd' fill='currentColor' fillRule='evenodd'>
        <path d='m2 8c0-2.20914 1.79086-4 4-4h12c2.2091 0 4 1.79086 4 4v8c0 2.2091-1.7909 4-4 4h-12c-2.20914 0-4-1.7909-4-4zm4-2c-1.10457 0-2 .89543-2 2v8c0 1.1046.89543 2 2 2h12c1.1046 0 2-.8954 2-2v-8c0-1.10457-.8954-2-2-2z' />
        <path d='m6.21913 8.37528c.34501-.43126.9743-.50118 1.40557-.15617l4.3753 3.50029 4.3753-3.50029c.4313-.34501 1.0606-.27509 1.4056.15617s.2751 1.06056-.1562 1.40556l-5.6247 4.49976-5.62469-4.49976c-.43127-.345-.50119-.9743-.15618-1.40556z' />
      </g>
    </svg>
  </SvgIcon>
);
