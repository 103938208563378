import React, { useState } from 'react';
import { Loading, useEditContext } from 'react-admin';
import { AdBoardDto } from '@keyops-hcp/dtos';

import IndividualFeed from './IndividualFeed';
import EmptyEngagement from './EmptyEngagement';
import EngagementOverview from './EngagementOverview';

const EngagementTabContent = () => {
  const [showIndividualFeed, setShowIndividualFeed] = useState<number | null>(
    null
  );
  const handleIndividualFeed = (value: number | null) =>
    setShowIndividualFeed(value);

  const {
    record: adBoardData,
    isLoading,
    isFetching,
  } = useEditContext<AdBoardDto>();

  if (isFetching || isLoading || !adBoardData) {
    return <Loading />;
  }

  // Main content when record is in 'live' or 'closed' state
  if (adBoardData.state !== 'live' && adBoardData.state !== 'closed') {
    return <EmptyEngagement />;
  }

  return (
    <>
      {showIndividualFeed ? (
        <IndividualFeed
          userId={showIndividualFeed}
          handleParticipantFeed={handleIndividualFeed}
        />
      ) : (
        <EngagementOverview handleIndividualFeed={handleIndividualFeed} />
      )}
    </>
  );
};

export default EngagementTabContent;
