export const Specialties = [
  "addictions_medicine",
  "anesthesia",
  "cardiac_surgery",
  "cardiology",
  "clinical_immunology",
  "colorectal_surgery",
  "community_medicine",
  "critical_care_medicine",
  "dermatology",
  "diagnostic_radiology",
  "emergency_medicine",
  "endocrinology",
  "endocrinology_metabolism",
  "family_practice",
  "gastroenterology",
  "gastrointestinal_endoscopy",
  "gastrointestinal_oncology",
  "general_internal_medicine",
  "general_surgery",
  "general_thoracic_surgery",
  "genetics",
  "geriatrics",
  "gynecologic_oncology",
  "haematology",
  "hepatology",
  "hospital_medicine",
  "infectious_disease",
  "internal_occupational_medicine",
  "interventional_cardiology",
  "interventional_radiology",
  "laboratory_medicine",
  "medical_oncology",
  "nephrology",
  "neurosurgery",
  "neurology",
  "nuclear_medicine",
  "obstetrics_and_gynaecology",
  "occupational_medicine",
  "ophthalmology",
  "orthopaedic_surgery",
  "otolaryngology",
  "paediatrics",
  "palliative_care",
  "pathology",
  "pediatric_rheumatology",
  "physical_medicine_rehabilitation",
  "plastic_surgery",
  "psychiatry",
  "radiation_oncology",
  "reproductive_endocrinology_and_infertility",
  "respiratory_disease",
  "rheumatology",
  "surgical_oncology",
  "urology",
  "urology_oncology",
  "vascular_surgery",
  "womens_health",
  "other",
] as const;

export type Specialty = (typeof Specialties)[number];
