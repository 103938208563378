import React, { useState, useCallback } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18next from '../../../../languages/i18n.config';
import { useStyles } from './styles';
import { useAdBoardById } from '../../../../hooks';

interface AdBoardContentProps {
  adBoardId: string;
}

export const AdBoardContent: React.FC<AdBoardContentProps> = ({
  adBoardId,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [openDescriptions, setOpenDescriptions] = useState(new Set<string>());
  const {
    data: adBoard,
    isLoading: loading,
    isError: error,
  } = useAdBoardById(adBoardId);

  // Toggle function for descriptions
  const toggleDescriptions = useCallback((sectionId: string) => {
    setOpenDescriptions((prevOpenDescriptions) => {
      const newOpenDescriptions = new Set(prevOpenDescriptions);
      if (newOpenDescriptions.has(sectionId)) {
        newOpenDescriptions.delete(sectionId);
      } else {
        newOpenDescriptions.add(sectionId);
      }
      return newOpenDescriptions;
    });
  }, []);

  // ToggleIcon used for showing description
  const ToggleIcon = useCallback(
    ({ id }: { id: string }) => (
      <ExpandMoreIcon
        sx={{
          ml: 1,
          color: theme.palette.keyops.blue.midnightBlue,
          transform: openDescriptions.has(id)
            ? 'rotate(180deg)'
            : 'rotate(0deg)',
          transition: 'transform 0.3s ease',
        }}
      />
    ),
    [openDescriptions, theme.palette.keyops.blue.midnightBlue]
  );

  // SubContent display
  const SubContent = useCallback(
    ({
      id,
      title,
      description,
    }: {
      id: string;
      title: string;
      description: string;
    }) => (
      <Box sx={{ mb: 1 }}>
        <Box sx={styles.clickableBox} onClick={() => toggleDescriptions(id)}>
          <Typography
            sx={{
              ...styles.title,
              fontWeight: 400,
            }}
          >
            {title}
          </Typography>
          {/* Conditionally render the toggle icon if description is not empty or null */}
          {description && <ToggleIcon id={id} />}
        </Box>
        {openDescriptions.has(id) && description && (
          <Box sx={{ mt: 0.5 }}>
            <Typography sx={styles.description}>{description}</Typography>
          </Box>
        )}
      </Box>
    ),
    [
      openDescriptions,
      toggleDescriptions,
      styles.clickableBox,
      styles.title,
      styles.description,
    ]
  );

  if (loading) return <div>{i18next.t('common.loading')}</div>;
  if (error || !adBoard) return <div>{i18next.t('common.error')}</div>;
  const sectionGroups = adBoard?.sectionGroups;
  const totalSections = sectionGroups.length;
  const totalActivities = sectionGroups.reduce((acc, sectionGroup) => {
    return acc + sectionGroup?.sections?.length;
  }, 0);
  const totalEstimatedTime = sectionGroups.reduce((acc, sectionGroup) => {
    return acc + sectionGroup?.estimatedTime;
  }, 0);

  return (
    <div>
      <Typography
        sx={{
          ...styles.description,
          mb: 2,
        }}
      >
        {`${totalSections} ${totalSections !== 1 ? 'sections' : 'section'} ● 
        ${totalActivities} ${
          totalActivities !== 1 ? 'activities' : 'activity'
        } ● 
        ${totalEstimatedTime} ${
          totalEstimatedTime !== 1 ? 'minutes' : 'minute'
        }`}
      </Typography>
      <Box sx={styles.contentBox}>
        {sectionGroups?.map((sectionGroup) => (
          <div key={sectionGroup.id}>
            <Box
              sx={{
                ...styles.columnStyle,
                borderTopLeftRadius: sectionGroup.index === 0 ? '8px' : 'none',
                borderTopRightRadius: sectionGroup.index === 0 ? '8px' : 'none',
              }}
            >
              <Box sx={styles.rowStyle}>
                <Box
                  sx={{
                    ...styles.clickableBox,
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => toggleDescriptions(sectionGroup.id)}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                    <Typography sx={styles.title}>
                      {sectionGroup.title}
                    </Typography>
                    {/* Conditionally render the toggle icon if description is not empty or null */}
                    {sectionGroup.description && (
                      <ToggleIcon id={sectionGroup.id} />
                    )}
                  </Box>
                  <Typography sx={styles.description}>
                    {sectionGroup?.sections &&
                      `${sectionGroup?.sections.length} 
                    ${
                      sectionGroup?.sections.length !== 1
                        ? 'activities'
                        : 'activity'
                    }
                    ● `}{' '}
                    {sectionGroup?.estimatedTime &&
                      `${sectionGroup?.estimatedTime} ${
                        sectionGroup?.estimatedTime !== 1 ? 'minutes' : 'minute'
                      }`}
                  </Typography>
                </Box>
                <Typography sx={styles.description}></Typography>
              </Box>
              {openDescriptions.has(sectionGroup.id) &&
                sectionGroup.description && (
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={styles.description}>
                      {sectionGroup.description}
                    </Typography>
                  </Box>
                )}
            </Box>
            <Box sx={{ ml: 5, mt: 1, mr: 1 }}>
              {sectionGroup?.sections?.map((section) => (
                <SubContent
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  description={section.description}
                />
              ))}
            </Box>
          </div>
        ))}
      </Box>
    </div>
  );
};
