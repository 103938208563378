import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { Link } from '@mui/material';
import React from 'react';

export const getUserName = (invite: AdBoardInviteDto) => {
  return `${invite.invitee.firstName} ${invite.invitee.lastName}`;
};

export const getUserNameDisplay = (invite: AdBoardInviteDto) => {
  const userName = getUserName(invite);
  if (invite.type === 'in-network') {
    return (
      <Link href={`#/Users/${invite.invitee.userId}/Show`}>{userName}</Link>
    );
  }
  return userName;
};
