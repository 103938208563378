import { Specialty } from "./specialty.type";

export const CardiologySubSpecialties = [
  "general_cardiology",
  "adult_congenital_heart_disease",
  "cardiac_critical_care",
  "cardiac_electrophysiology",
  "cardiothoracic_imaging",
  "cardiovascular_disease_genetics",
  "clinical_informatics",
  "clinical_pharmacology",
  "echocardiography",
  "exercise_physiology_and_cardiac_rehabilitation",
  "heart_failure_and_transplantation",
  "interventional_cardiology",
  "non_invasive_cardiology",
  "nuclear_cardiology",
  "peripheral_endovascular_interventional_cardiology",
  "preventive_cardiology",
  "vascular_medicine",
] as const;

export const DermatologySubSpecialties = [
  "general_dermatology",
  "acne_and_rosacea",
  "clinical_informatics",
  "clinical_pharmacology",
  "cosmetic_dermatology",
  "dermatologic_surgery",
  "dermatopathology",
  "pediatric_dermatology",
] as const;

export const FamilyPracticeSubSpecialties = [
  "general_family_medicine",
  "addiction_medicine",
  "adolescent_medicine",
  "bariatric_medicine",
  "behavioral_medicine",
  "clinical_informatics",
  "clinical_pharmacology",
  "complementary_and_integrative_medicine",
  "emergency_medicine",
  "geriatric_medicine",
  "headache_medicine",
  "health_and_culture",
  "healthcare_research_and_healthcare_policy",
  "hiv_aids_medicine",
  "hospice_and_palliative_medicine",
  "hospital_medicine",
  "hospital_pediatrics",
  "obesity_and_weight_management",
  "obstetrics",
  "occupational_medicine",
  "preventive_medicine",
  "rural_health",
  "sleep_medicine",
  "sports_medicine",
  "urgent_care",
] as const;

export const GastroenterologySubSpecialties = [
  "general_gastroenterology",
  "advanced_endoscopic_interventional",
  "anorectal_function_and_pelvic_floor_dysfunction",
  "bariatric_medicine",
  "biliary_tract_disease",
  "clinical_informatics",
  "clinical_pharmacology",
  "esophagus_disease",
  "gastrointestinal_cancer",
  "gastrointestinal_functional_and_motility_disorders",
  "hepatology_and_liver_transplantation",
  "inflammatory_bowel_disease",
  "malabsorption",
  "nutrition",
  "pancreas_disease",
] as const;

export const InterventionalRadiologySubSpecialties = [
  "general_interventional_radiology",
  "hospice_and_palliative_medicine",
  "neuroradiology",
  "nuclear_radiology",
  "pain_medicine",
  "pediatric_radiology",
] as const;

export const ObstetricsAndGynecologySubSpecialties = [
  "general_obstetrics_and_gynecology",
  "birth_defect_and_fetal_treatment",
  "clinical_pharmacology",
  "complementary_and_integrative_medicine",
  "critical_care_medicine",
  "general_gynecology",
  "general_obstetrics",
  "gynecologic_oncology",
  "hospice_and_palliative_medicine",
  "hospitalist",
  "maternal_and_fetal_medicine_high_risk",
  "minimally_invasive_endoscopic_surgery",
  "pediatric_gynecology",
  "reproductive_endocrinology_and_infertility",
  "reproductive_genetics_prenatal_genetics",
  "std_gynecology",
  "urogynecology_and_pelvic_reconstructive_surgery",
] as const;

export const MedicalOncologySubSpecialties = [
  "breast",
  "cns",
  "gastrointestinal_gi",
  "general_oncology",
  "genitourinary_gu",
  "gynecological",
  "head_and_neck",
  "hematological",
  "lung",
  "malignant_hematology",
  "skin",
] as const;

export const RheumatologySubSpecialties = [
  "general_rheumatology",
  "clinical_informatics",
  "clinical_pharmacology",
  "crystal_related",
  "geriatric_rheumatology",
  "lupus_and_related",
  "myositis",
  "osteoarthritis",
  "osteoporosis_and_bone_metabolism",
  "rheumatoid_arthritis",
  "scleroderma",
  "spondyloarthropathy",
  "systemic_pain_and_inflammatory_syndromes",
  "vasculitis",
] as const;

export const SubSpecialties = [
  ...CardiologySubSpecialties,
  ...DermatologySubSpecialties,
  ...FamilyPracticeSubSpecialties,
  ...GastroenterologySubSpecialties,
  ...InterventionalRadiologySubSpecialties,
  ...MedicalOncologySubSpecialties,
  ...ObstetricsAndGynecologySubSpecialties,
  ...RheumatologySubSpecialties,
] as const;

export type SubSpecialty = (typeof SubSpecialties)[number];

export const SubSpecialtiesBySpecialty: Partial<
  Record<Specialty, readonly SubSpecialty[]>
> = {
  cardiology: CardiologySubSpecialties,
  dermatology: DermatologySubSpecialties,
  family_practice: FamilyPracticeSubSpecialties,
  gastroenterology: GastroenterologySubSpecialties,
  interventional_radiology: InterventionalRadiologySubSpecialties,
  medical_oncology: MedicalOncologySubSpecialties,
  obstetrics_and_gynaecology: ObstetricsAndGynecologySubSpecialties,
  rheumatology: RheumatologySubSpecialties,
};
